html,
body {
  background: #f5f5f5;
  color: #000000;
  height: 100%;
  font-family: 'BG-Regular' !important;
}
body {
  background: url('../images/icons/confetti.svg');
  background-repeat: no-repeat;
  background-repeat: repeat-x;
}

.noValue {
  margin: 25px 0;
  text-align: left;
  opacity: 0.3;
  font-size: 16px;
  font-weight: bold;
}
.policy-error {
  display: none !important;
}
button {
  outline: none;
  cursor: pointer;
}
.mb-only {
  display: none;
}
#app {
  height: 100%;
}
#password {
  min-width: 250px;
}
.file-upload-fx {
  padding: 0 !important;
  width: 160px !important;
  vertical-align: top;
  margin-left: 25px;
  margin-top: -5px;
}
.file-attach span {
  overflow: hidden;
  color: #895b85 !important;
}
.file-attach {
  width: 158px;
  height: 36px;
  border: 1px solid #895b85;
  color: #895b85;
  text-align: center;
  line-height: 36px;
  border-radius: 4px;
  font-size: 12px;
  margin-right: 12px;
  overflow: hidden;
  padding: 0 10px;
}
.width-150 {
  width: 158px;
  position: relative;
  display: block;
  top: -7px;
  text-align: center;
}
.va-top {
  vertical-align: top;
}
.danger {
  color: #881414;
  font-weight: bold;
}
.filefield .remove-sign {
  position: absolute !important;
  top: -14px;
  right: -2px;
  width: 30px;
  height: 30px;
  line-height: 30px;
}
.filefield {
  position: relative;
  width: 100%;
}
.remove-sign {
  border-radius: 128px;
  border: 1px solid #895c85;
  text-align: center;
  width: 25px;
  height: 25px;
  background: #fcfcfc;
  display: block;
  font-size: 28px;
  line-height: 26px;
  color: #895c85;
  cursor: pointer;
  position: relative;
  top: -45px;
  right: -10px;
}
.button-transparent.normalize {
  top: 0 !important;
  right: 0 !important;
  float: none !important;
  position: normal !important;
}
.inline {
  display: inline-block;
}
.wrap {
  height: 96vh;
  padding: 32px;
}
.clearfix {
  clear: both;
}
.hidden-ref {
  position: absolute;
  top: 600px;
  left: 100px;
  opacity: 0;
}
.logo {
  background: url('../images/logo.svg');
  width: 80px;
  height: 27px;
  margin: auto;
  margin-top: 40px;
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
  position: relative;
}
.footer {
  position: absolute;
  bottom: -60px;
  width: 70%;
  height: 2.5rem;
  .left {
    display: flex;
    width: 90%;
    float: left;
    span {
      margin: 0 10px;
    }
  }

  .social {
    float: right;
    margin-left: 10px;
    img {
      padding-left: 0;
      padding-right: 13px;

      &:first-of-type {
        margin-top: 1px;
      }
      &:nth-of-type(2) {
        margin-top: 0px;
      }
      &:last-of-type {
        margin-top: 1px;
      }
    }
  }
}

.vida-selector {
  position: absolute;
  left: 0;
  z-index: 1;
  top: 71px;
  cursor: pointer;
  height: 300px;
  overflow: hidden;
  overflow-y: scroll;
  background: #efefef;
  border: 1px solid #ccc;
  width: 95%;

  span {
    width: 100%;
    line-height: normal !important;
    display: block;
    font-family: 'BG-Regular' !important;
    font-size: 16px !important;
    margin: 0 !important;
    padding: 8px 0 8px 10px;

    &:hover {
      background: #ccc;
    }
  }
}

.for-selected {
  width: 320px;
  float: left;
}

.policies-selected {
  border-radius: 10px;
  background-color: #dedede;
  padding: 0 6px;
  float: left;
  margin: 32px 10px 0 0;
  .count {
    float: left;
    height: 100%;
    text-align: center;
    width: 40%;
    font-size: 30px;
  }

  .text {
    color: #383837;
    width: 60%;
    float: right;
    line-height: 16px;
    padding: 7px 0;
  }
}

.content-block {
  float: left;
  padding: 31px 0 0 212px;
  margin-bottom: 31px;
  width: 100%;
  min-height: 1000px;
  padding-bottom: 2.5rem;
  .user-details-header-dashboard {
    display: flex;
    align-items: center;
    justify-content: flex-start;
  }
  .button-transparent {
    width: 166px;
    height: 36px;
    border: 1px solid #895b85;
    color: #895b85;
    text-align: center;
    line-height: 36px;
    border-radius: 4px;
    text-transform: uppercase;
    font-size: 12px;
    cursor: pointer;
  }
  .button-mainStructure {
    width: 166px;
    height: 36px;
    background-color: #895b85;
    border: 1px solid #383837;
    color: white;
    text-align: center;
    line-height: 36px;
    border-radius: 4px;
    overflow: 0;
    text-transform: uppercase;
    font-size: 12px;
    cursor: pointer;
    margin-bottom: 8px;
    opacity: 1;
    transition: opacity 0.3s ease-in-out;
    &:hover {
      opacity: 0.8;
    }
  }
  .button-yellow {
    background-color: #ffdd42;
    color: #383837;
  }

  h1 {
    font-family: 'BG-Bold';
    font-size: 30px;
    margin: 0;
    line-height: 45px;
    color: black;
  }

  h2 {
    font-family: 'BG-Bold';
    font-size: 25px;
    margin: 0;
    color: black
  }

  p {
    margin: 0;
    padding-top: 24px;
  }

  .card-block {
    position: relative;
    display: inline-block;
    width: 115px;
    height: 165px;
    border: 1px solid #895b85;
    border-radius: 12px;
    margin: 0 18px 18px 0;
    padding: 5px;

    .title {
      font-family: 'BG-Bold';
      font-size: 15px;
      color: #562b53;
      width: 100%;
      text-align: center;
    }

    .plus {
      padding-top: 20px;
    }

    .shield {
      display: block;
      width: 60px;
      height: 75px;
    }

    .shield-white {
      display: none;
    }

    .image {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      width: 110px;
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
    }

    &:hover {
      cursor: pointer;
      background-color: #895d85;
      .plus {
        display: block;
      }
      .shield {
        display: none;
      }
      .shield-white {
        display: block;
      }
      .title {
        color: #fff;
      }
    }

    &.active {
      cursor: pointer;
      background-color: #895d85;
      .plus {
        display: block;
      }
      .shield {
        display: none;
      }
      .shield-white {
        display: block;
      }
      .title {
        color: #fff;
      }
    }
    &.selected {
      cursor: pointer;
      background-color: #895d85;
      .plus {
        display: block;
      }
      .shield {
        display: none;
      }
      .shield-white {
        display: block;
      }
      .title {
        color: #fff;
      }
    }
  }
  .arrow-mb {
    display: none;
  }
  .cards {
    margin-top: 31px;
  }

  .description-short-text {
    width: 350px;
    padding: 0;
    margin: 0;
    margin-top: 16px;
    font-size: 16px;
    line-height: 1.38;
    font-family: 'BG-Light';
  }

  .extra-details {
    margin-top: 53px;
  }

  .page-description {
    margin-top: 33px;

    &.with-action {
      float: left;
    }
  }

  .policies-list-container {
    position: relative;

    .noValue {
      margin: 25px 0;
      text-align: left;
      opacity: 0.3;
      font-size: 16px;
      font-weight: bold;
    }
    h2 {
      margin-top: 24px;
      float: left;
    }

    .button-transparent {
      float: left;
      margin-top: 15px;
    }
    .subtitle-wallet {
      width: 100%;
    }
    .policies-list {
      margin-top: 20px;

      &.short {
        width: 90%;
      }
    }

    .policy-item {
      cursor: pointer;
      border-radius: 10px;
      background-color: #fff;
      // height: 110px;
      height: 125px;
      width: 100%;
      margin-bottom: 12px;
      position: relative;
      box-shadow: 0px 7px 16px #ddd;

      .policy-item__title {
        padding: 27px 0 23px 15px;
        z-index: 1;

        img {
          margin-bottom: 5px;
          width: 55px;
        }

        span {
          padding-left: 18px;
          font-family: 'BG-Bold';
          font-size: 16px;
          color: #383837;
          width: 110px;
          display: inline-block;
          vertical-align: middle;
          word-wrap: break-word;
        }
      }

      &.alert {
        border: 1px solid #ffdd42;
        right: 0px;
        top: 0px;
        height: 100%;
        padding: 0;
      }
      &.expired {
        border: 1px solid #b181aa;
        right: 0px;
        top: 0px;
        height: 100%;
        padding: 0;
      }
      &.pending {
        border: 1px solid #b181aa;
        right: 0px;
        top: 0px;
        height: 100%;
        padding: 0;
      }
      &.ended {
        border: 1px solid #b181aa;
        right: 0px;
        top: 0px;
        height: 100%;
        padding: 0;
      }

      .policy-item__content {
        position: absolute;
        z-index: 2;
        right: 0px;
        top: 0px;
        background: #fff;
        border-radius: 8px;
        width: calc(100% - 202px);
        height: 100%;
        display: inline-flex;
        overflow: hidden;

        .policy-item__alert {
          cursor: pointer;
          display: block;
          position: absolute;
          right: -1px;
          top: -1px;
          background: #ffdd42;
          width: 240px;
          height: 24px;
          border-bottom-left-radius: 8px;
          text-align: center;
          color: #000;

          span:first-child {
            font-size: 12px;
          }

          span:last-child {
            font-size: 12px;
            font-family: 'BG-Medium';
          }

          img {
            vertical-align: baseline;
            padding: 0 6px 0 7px;
            top: 1px;
            position: relative;
          }
        }
        .policy-item__none {
          position: absolute;
          right: -1px;
          top: -1px;
          background: white;
          width: 240px;
          height: 24px;
          z-index: 2;
        }
        .policy-item__expired {
          cursor: pointer;
          display: block;
          position: absolute;
          right: -1px;
          top: -1px;
          background: #b181aa;
          width: 240px;
          height: 24px;
          border-bottom-left-radius: 8px;
          text-align: center;
          color: #fff;

          span:first-child {
            font-size: 12px;
          }

          span:last-child {
            font-size: 12px;
            font-family: 'BG-Medium';
          }

          img {
            vertical-align: middle;
            padding: 0 6px 0 11px;
          }
        }
        .policy-item__pending {
          background: #666666;
          z-index: 3;
          display: block;
          position: absolute;
          right: -1px;
          top: -1px;
          width: 240px;
          height: 24px;
          border-bottom-left-radius: 8px;
          text-align: center;
          color: #fff;
          span {
            font-size: 12px;
            font-family: 'BG-Medium';
          }
        }

        .policy-item__open {
          color: #707070;
          font-family: 'BG-Bold';
          position: absolute;
          right: 0px;
          margin: auto;
          font-size: 18px;
          line-height: 106px;
          padding: 0 10px;
        }

        .policy-item__node {
          flex: 25%;
          margin: 10px 0;
          border-right: 1px solid #ccc;
          padding: 12px 0;

          &.first {
            border-left: 1px solid #ccc;
          }

          &:last-child {
            border: 0;
          }

          span,
          strong {
            display: block;
            text-align: center;
            color: #383837;
          }

          span {
            font-size: 12px;
          }

          strong {
            font-size: 16px;
            padding-top: 5px;
            white-space: normal;
            width: 95%;
            margin: auto;
          }
        }
      }
    }
  }
}

.side-menu {
  width: 160px;
  height: calc(100% - 64px);
  border-radius: 16px;
  background: #383837;
  float: left;
  position: fixed;
  z-index: 10;
  left: 32px;
  margin-top: 32px;
  font-size: 12px;
  font-family: BG-bold;
}
.logout {
  width: 85px;
  color: #fff;
  font-size: 16px;
  position: absolute;
  bottom: 44px;
  margin: auto;
  left: 0;
  right: 0;
  text-align: center;
  cursor: pointer;

  img {
    vertical-align: text-bottom;
  }
}

.navigation {
  margin-top: 52px;

  .buy-policy {
    width: 112px;
    height: 36px;
    margin: auto;
    border-radius: 6px;
    background-color: #b181aa;
    font-family: 'BG-Bold';
    color: #fff;
    text-align: center;
    line-height: 34px;
    margin-top: 28px;
    font-size: 14px;
    cursor: pointer;

    img {
      display: inline !important;
    }

    span {
      line-height: 13px;
      width: 50px;
      display: inline-block;
      position: relative;
      top: 4px;
    }
  }

  ul {
    padding: 0;
    margin: 0;
    li {
      padding: 0;
      margin: 0;
      list-style: none;
      margin: 19px 0 19px 20px;

      img {
        vertical-align: text-bottom;
        width: 20px;
        height: 20px;
        margin-right: 8px;
      }

      img:first-child {
        display: none;
      }

      &:hover {
        img:first-child {
          display: inline;
        }
        img:nth-of-type(2) {
          display: none;
        }

        a {
          color: #f7cb8b;
          text-decoration: none;
        }
      }

      a {
        padding: 8px 0;
        color: #fff;
        font-size: 12px;
      }
    }

    li.active {
      img:first-child {
        display: inline;
      }
      img:nth-of-type(2) {
        display: none;
      }

      a {
        color: #f7cb8b;
        text-decoration: none;
      }
    }
  }
}

.input-md {
  width: 95%;
  border: 1px solid #aacecd;
  border-radius: 3px;
  padding: 8px 0 8px 8px;
  background: rgba(0, 0, 0, 0);
  font-size: 14px;
  font-family: 'BG-Regular';
  outline: none !important;
  transition: border 0.2s ease-in-out;
  &:focus {
    border: 1px solid #aacecd;
  }
}
.input-password {
  .ant-input {
    border: 1px solid #aacecd !important;
    &:focus {
      box-shadow: none;
    }
  }
  .ant-input-suffix {
    top: 33% !important;
  }
  width: 95%;
  border-radius: 3px;
  background: rgba(0, 0, 0, 0);
  font-size: 14px;
  font-family: 'BG-Regular';
  outline: none !important;
  transition: border 0.2s ease-in-out;
  &:focus {
    border: 1px solid #aacecd;
  }
}
.multiple-checkbox-wrapper {
  width: 95%;
  position: relative;
  .dropdown-span {
    position: relative;
    border: 1px solid #aacecd;
    border-radius: 3px;
    padding: 3px 8px;
    cursor: pointer;
    &:after {
      position: absolute;
      top: 0;
      right: 8px;
      content: '\2335';
      display: block;
    }
  }
  .multiple-checkbox-content {
    position: absolute;
    top: 39px;
    z-index: 1;
    background-color: #fff;
    left: 0;
    right: 0;
    height: 0;
    visibility: hidden;
    overflow-y: scroll;
    overflow-x: hidden;
    padding-top: 8px;
    transition: opacity 0.3s ease-in-out;
    .checkbox-md {
      margin-bottom: 0;
      display: block;
    }
  }
  .multiple-checkbox-content::-webkit-scrollbar-track {
    box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    background-color: #f5f5f5;
  }

  .multiple-checkbox-content::-webkit-scrollbar {
    width: 2px;
    background-color: #f5f5f5;
  }

  .multiple-checkbox-content::-webkit-scrollbar-thumb {
    background-color: #aacecd;
    border: 2px solid #aacecd;
    border-radius: 40px;
  }
  &:hover {
    .multiple-checkbox-content {
      visibility: visible;
      height: 150px;
      border: 1px solid #aacecd;
      border-radius: 3px;
    }
  }
}
.react-datepicker-wrapper {
  width: 100%;
  .input-md {
    min-height: 40px;
  }
}
.checkbox-md {
  margin: 10px 10px 10px 0;
  padding-left: 10px;
  width: 100%;
  span {
    position: relative;
    display: block;
    background-color: rgba(0, 0, 0, 0);
    color: #383837;
    font-family: 'BG-Medium';
    text-transform: uppercase;
    font-size: 12px;
    padding: 5px 30px;
    &:before {
      position: absolute;
      content: '';
      top: 5px;
      left: 3px;
      padding: 8px;
      width: 16px;
      height: 16px;
      border: 1px solid #aacecd;
      border-radius: 50%;
      background-repeat: no-repeat;
    }
  }

  input {
    opacity: 0;
    width: 0px;
    height: 0px;
    display: none;
  }

  input:checked + span {
    position: relative;
    color: #aacecd;
    &:before {
      position: absolute;
      content: '';
      top: 3px;
      left: 0;
      border: 0;
      width: 30px;
      height: 30px;
      background: url('../images/icons/checkmark.svg');
      background-repeat: no-repeat;
    }
  }
}

.select-md {
  position: relative;
  cursor: pointer;
  border: 0;
  background: rgba(0, 0, 0, 0);
  border: 1px solid #895c85;
  border-radius: 5px;
  font-size: 14px;
  outline: none;
  padding: 8px 0 8px 8px;
  width: 95%;
}
.btn-wrapper {
  .savebtn {
    cursor: pointer;
    margin: 30px 0 30px 20px;
  }
}

.user-settings {
  margin-top: 40px;
  .user-details,
  .user-about {
    width: 100%;
    height: 100%;
    display: block;
    position: relative;
    float: left;
    border: 1px solid #aacecd;
    border-radius: 5px;
    padding: 15px;
    background: #fff;
    .main-title {
      margin-bottom: 35px;
      padding-left: 50px;
      color: #895b85;
      border-bottom: 1px solid #aacecd;
      span {
        height: 36px;
        width: 36px;
        font-size: 25px;
        color: #fff;
        line-height: 36px;
        text-align: center;
        background: #895b85;
        border-radius: 50%;
        position: absolute;
        left: 16px;
        top: 18px;
      }
    }
    .form-node {
      width: 50%;
      float: left;
      margin-bottom: 32px;
    }
  }
}

.add-insurance {
  margin-top: 40px;
  .user-details,
  .user-about {
    width: 100%;
    height: 100%;
    display: block;
    position: relative;
    float: left;
    border: 1px solid #aacecd;
    border-radius: 5px;
    padding: 15px;
    background: #fff;
    .main-title {
      margin-bottom: 35px;
      padding-left: 50px;
      color: #895b85;
      border-bottom: 1px solid #aacecd;
      span {
        height: 36px;
        width: 36px;
        font-size: 25px;
        color: #fff;
        line-height: 36px;
        text-align: center;
        background: #895b85;
        border-radius: 50%;
        position: absolute;
        left: 16px;
        top: 18px;
      }
    }
    .form-node {
      width: 100%;
      float: left;
      margin-bottom: 32px;
      .vida-selector {
        width: 46%;
      }
      .form-object {
        width: 46%;
        .select-md,
        .input-md {
          width: 100%;
        }
      }
      .checkbox-wrapper {
        display: flex;
        max-width: 100%;
        flex-wrap: wrap;

        .checkbox-md {
          max-width: 46%;
          flex: 0 0 50%;
          padding: 0;

          span {
            position: relative;
            display: block;
            background-color: rgba(0, 0, 0, 0);
            color: #383837;
            font-family: 'BG-Medium';
            text-transform: uppercase;
            text-align: center;
            font-size: 12px;
            padding: 9px 0;
            border: 1px solid #ddd;
            border-radius: 6px;
            transition: border 0.3s ease-in-out;
            &:hover {
              border: 1px solid #aacecd;
            }
            &:before {
              display: none;
            }
          }

          input {
            opacity: 0;
            width: 0px;
            height: 0px;
            display: none;
          }

          input:checked + span {
            position: relative;
            background: #aacecd;
            color: #000000;
            &:before {
              display: none;
            }
          }
        }
      }
    }
    .width-correction {
      width: 50%;
    }
  }
}
@media all and (min-width: 320px) and (max-width: 787px) {
  .input-password {
    width: 95%;
  }
  .footer {
    .left {
      flex-direction: column;
      text-align: center;
      .img-wrapper {
        width: 100%;
      }
    }
    .social {
      float: none;
      text-align: center;
      margin-top: 10px;
    }
  }
  .user-settings {
    .user-details,
    .user-about {
      .main-title {
        font-size: 24px;
      }
      .form-node {
        width: 100%;
      }
    }
  }
  .add-insurance {
    .user-details,
    .user-about {
      .main-title {
        font-size: 24px;
      }
      .form-node {
        width: 100%;
        .form-object {
          width: 100%;
        }
        .checkbox-wrapper {
          display: flex;
          max-width: 100%;
          flex-wrap: wrap;
          margin-top: 15px;
          .checkbox-md {
            max-width: 100%;
            flex: 0 0 100%;
            padding: 0;
          }
        }
      }

      .width-correction,
      .input-md,
      .select-md {
        width: 100%;
      }
    }
  }
}
.space-bottom {
  margin-bottom: 30px;
}
.selectList {
  position: relative;
  width: 100%;
  -webkit-appearance: none;
  -moz-appearance: none;
  -ms-appearance: none;
  appearance: none;
  cursor: pointer;
  background: transparent;
  border: 1px solid #aacecd;
  border-radius: 3px;
  padding: 5px 8px;
  font-family: 'BG-Regular';
  line-height: 1.94;
  outline: none !important;
  transition: border 0.2s ease-in-out;
  &:focus {
    border: 1px solid #895b85;
  }
  option {
    padding: 20px;
  }
}
.select-wrapper {
  display: block;
  position: relative;
  width: 95%;

  &:after {
    position: absolute;
    pointer-events: none;
    top: 0;
    right: 8px;
    content: '\2335';
    display: block;
  }
}

/* Remove IE arrow */
.selectList:-ms-expand {
  display: none;
}

.subtitle {
  margin-bottom: 40px !important;
  margin-top: 100px !important;
}

.form-node {
  margin-bottom: 52px;
  position: relative;
  span:first-child {
    font-size: 14px;
    font-family: 'BG-Medium';
    line-height: 2.25;
    color: #383837;
    display: block;
  }
}

.button-solid {
  cursor: pointer;
  padding: 12px 28px;
  background: #895c85;
  color: #fff;
  font-family: 'BG-Medium';
  text-align: center;
  border-radius: 8px;
  font-size: 14px;
}


.swal2-styled.swal2-default-outline:focus {
  box-shadow: none !important;
}
.next-button {
  width: 150px;
  text-transform: uppercase;
  position: relative;
  bottom: 0;
  right: 0;
  margin: 10px 15px 30px 0;
  display: inline-block;
  cursor: pointer;
  border: 0;
  &.unselect {
    width: 180px;
    margin-top: 32px;

    .button-solid {
      padding: 12px 28px;
      border: 1px solid #895c85;
      color: #895c85;
      background: transparent;
    }
  }
}

.hidden {
  display: none;
}

.back-arrow {
  cursor: pointer;
  margin-bottom: 21px;
}

.form-content {
  margin-top: 48px;
}

.hc-filters {
  margin-top: 52px;
  position: relative;

  .hc-filter-button {
    border: 1px solid #895c85;
    border-radius: 6px;
    text-align: center;
    width: 150px;
    height: 45px;
    font-size: 12px;
    line-height: 45px;
    font-family: 'BG-Medium';
    color: #895c85;
    cursor: pointer;

    img {
      position: absolute;
      margin: auto;
      top: 0;
      bottom: 0;
      left: 16px;
    }
  }

  .hc-filters-modal {
    position: absolute;
    background: #fff;
    border-radius: 12px;
    width: 244px;
    min-height: 100px;
    padding: 24px;
    z-index: 10;
    left: 170px;
    top: 0;
    box-shadow: 0px 6px 18px rgba(74, 74, 74, 0.15);

    .close {
      cursor: pointer;
      position: absolute;
      right: 15px;
      top: 15px;
    }

    span {
      font-size: 17px;
      color: #393939;
      font-family: 'BG-Bold';
    }
    ul {
      margin: 0;
      padding: 0;
      margin-top: 22px;

      li {
        list-style: none;
        margin-bottom: 14px;

        :checked + label {
          color: #895a85;
        }

        input {
          display: none;
        }

        img {
          vertical-align: text-bottom;
        }

        label {
          margin: 0;
          font-size: 14px;
          padding-left: 9px;
          font-family: 'BG-Medium';
          cursor: pointer;
        }
      }
    }
  }
}

.hc-content {
  margin-top: 40px;

  .block {
    width: 190px;
    height: 239px;
    overflow: hidden;
    border-radius: 12px;
    position: relative;
    display: inline-block;
    margin-right: 22px;
    vertical-align: top;
    margin-bottom: 25px;
    box-shadow: 0px 6px 18px rgba(74, 74, 74, 0.15);

    img {
      width: 150%;
    }
    .description {
      width: 190px;
      height: 109px;
      background: #fff;
      border-radius: 12px;
      position: absolute;
      padding: 16px;
      bottom: 0;

      a {
        text-transform: uppercase;
        font-size: 13px;
        font-family: 'BG-Black';
        color: #383837;

        &:hover {
          color: #895c85;
        }
      }
      p {
        font-size: 12px;
        font-family: 'BG-Light';
        padding-top: 4px;
      }
    }
  }
}

.article-content {
  font-family: 'BG-Medium';
  line-height: 1.57;
  font-size: 14px;
  margin-top: 32px;
  color: #000000;

  p,
  strong,
  h1,
  h2,
  h3 {
    margin: 0;
    padding: 0;
  }

  img,
  embed,
  oembed {
    width: 100%;
  }

  img {
    border-radius: 10px;
  }
}
.clickable-wrapper {
  position: relative;
  top: 25px;
}
.clickable {
  margin: 5px 5px 0 5px;
  cursor: pointer;
  width: 120px;
  text-align: center;
  max-height: 30px;
  border: 2px solid #895c85;
  border-radius: 5px;
  padding: 2px;
  p {
    padding-top: 0;
  }
}
.comprar {
  background-color: #b181aa;
  border: 2px solid #b181aa;

  color: white;
}
.renovar {
  background-color: #ffdd42;
  border: 2px solid #ffdd42;
}

.auth-page {
  background: url('../images/pattern.svg');
  width: 100%;
  min-height: 100%;
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  left: 0;
  top: 0;
  padding: 32px;
  overflow-x: hidden;

  .auth-logo {
    position: absolute;
    left: 2%;

    img {
      width: 160px;
    }
  }

  .auth-container {
    background: #fff;
    border-radius: 24px;
    width: 50%;
    float: right;
    padding: 56px 41px 46px 41px;

    .forgot {
      display: block;
      width: 100%;
      // margin-top: -20px;
      margin-bottom: 15px;
    }

    h1 {
      font-size: 30px;
      font-family: 'BG-Bold';
      line-height: 0.88;
    }
    .form {
      padding-top: 35px;
    }
    .recovery {
      h1 {
        padding-bottom: 15px;
      }

      p {
        color:black;
        font-size: 16px;
      }
    }

    label {
      display: block;
      font-size: 16px;
      font-family: 'BG-Light';
      margin-bottom: 6px;
      cursor: pointer;
    }

    input[type='password'],
    input[type='text'] {
      min-width: 250px;
      margin-bottom: 26px;
      font-family: 'BG-Regular';
      font-size: 16px;
    }

    input[type='password'] {
      letter-spacing: 3px;
    }

    button {
      border: 0;
      text-transform: uppercase;
    }

    span {
      cursor: pointer;
    }

    input[type='checkbox'] {
      opacity: 0;
    }
    .consents-wrapper {
      display: flex;
    }

    .ellipsis {
      padding: 7px;
      height: 15px;
      border: solid 1px #aacecd;
      background: #f0f0f0;
      border-radius: 128px;
      display: inline-block;
      position: relative;
      top: 2px;
      margin-right: 12px;
      transition: all 0.2s ease-in-out;
      &.active {
        border: solid 1px #895a85;
        background: #895a85;
      }
    }
  }

  .btn-table {
    img:first-child {
      padding-right: 24px;
    }
  }
}

.settings {
  h2 {
    margin-top: 40px;
    font-size: 20px;
  }

  .switches {
    display: block;
    width: 450px;
    .switch-object {
      display: flex;
      align-items: center;
      margin-top: 26px;
      cursor: pointer;

      span {
        width: 80%;
        display: inline-block;
      }

      .switch {
        width: 32px;
        height: 16px;
        border-radius: 20px;
        background-color: #ffffff;
        position: relative;
        vertical-align: middle;
        display: inline-block;
        margin-left: 20px;
        &.on {
          border: solid 1px #b181aa;
          &:after {
            content: '';
            display: block;
            border-radius: 128px;
            background-color: #b181aa;
            right: 2px;
            top: 1px;
            position: absolute;
            width: 12px;
            height: 12px;
          }
        }

        &.off {
          border: solid 1px #b5b5b5;
          &:after {
            content: '';
            display: block;
            border-radius: 128px;
            background-color: #b5b5b5;
            left: 2px;
            top: 1px;
            position: absolute;
            width: 12px;
            height: 12px;
            transition: all 3s cubic-bezier(0.2, 0.85, 0.32, 1.2);
          }
        }
      }
    }
    .toggled-text {
      display: inline-block;
      visibility: visible;
      opacity: 1;
      width: 500px;
      margin: 30px 0;
      transition: all 0.4s ease-in-out;
      .button-solid {
        margin-top: 10px;
        width: 140px;
        float: left;
      }
    }
    .hide {
      display: inline-block;
      width: 500px;
      height: 20px;
      visibility: hidden;
      opacity: 0;
      transition: all 0.4s ease-in-out;
      .button-solid {
        margin-top: 10px;
        width: 140px;
        float: left;
      }
    }
    @media all and (max-width: 786px) {
      width: 100%;
    }
  }
}

.policy-view {
  display: block;
  width: 734px;

  .buttons-fx-md {
    display: flex;
    margin-bottom: 30px;
    justify-content: flex-start;
  }
  .button-transparent {
    margin-left: 14px;
    font-family: 'BG-Medium';
    font-size: 12px;
    width: 166px;
    height: 36px;
    &:first-child {
      margin-left: 0;
    }

    img {
      vertical-align: text-top;
      left: -6px;
      position: relative;
    }
  }
  .content {
    border-radius: 8px;
    background: #fff;
    width: calc(100% - 20px);
    height: 100%;
    border: 3px solid #f4e9d3;

    .element {
      padding: 17px 0px 16px 0px;
      border-bottom: 1px solid #f1f1f1;
      width: 95%;
      margin: auto;
      display: flex;
      input {
        width: 145px;
        height: 40px;
        border: 1px solid #ccc;
        border-radius: 3px;
        padding: 8px;
      }

      .selectList {
        border: 1px solid #ccc;
        font-family: 'BG-Regular';
        width: 120px;
      }
      .form-object {
        display: inline-block;
        position: relative;
        .select-wrapper {
          .selectList {
            width: 145px;
          }
        }
      }

      .vida-selector {
        display: block;
        height: 200px;
        width: 350px;
        position: absolute;
        z-index: 10;
        top: 40px;
      }

      &:last-child {
        border: 1 !important;
      }

      strong {
        width: 140px;
        display: inline-block;
        color: #383837;
        font-family: 'BG-Bold';
        vertical-align: top;
        padding-right: 15px;
      }

      img {
        padding-right: 5px;
      }

      p {
        padding: 0;
        margin: 0;
        display: inline;
        color: #646464;
        width: 70%;
        &.scroll {
          overflow-y: scroll;
          height: 100px;
        }
      }

      .mr-10 {
        margin-right: 10px;
      }
      .show-box:not(:last-child):after {
        content: ',\00a0';
      }
      .multiple {
        width: 70%;
        .multiple-checkbox-wrapper-view {
          display: flex;
          flex-wrap: wrap;
          justify-content: flex-start;
        }
      }
      .checkbox-wrapper {
        display: flex;
        flex-wrap: wrap;
        width: 70%;
        .checkbox-md {
          width: 200px;
        }
      }
    }
  }
}

.email-page {
  h1 {
    font-size: 30px;
    width: 550px;
    padding-bottom: 36px;

    strong {
      font-family: 'BG-Black';
    }
  }

  h2,
  ul,
  p {
    width: 420px;
  }

  h2 {
    font-size: 18px;
    font-family: 'BG-Medium';
    margin-bottom: 10px;
  }

  hr {
    margin: 20px 0;
    width: 420px;
  }

  ul {
    padding-left: 18px;

    li {
      padding-bottom: 4px;
    }
  }

  p {
    font-size: 14px;
    margin: 0;
    padding: 0;
  }

  .button-solid,
  .button-transparent {
    text-transform: uppercase;
    width: 150px;
    height: 45px;
    padding: 12px 20px;
    line-height: 21px;
    font-family: 'BG-Bold';
    border-radius: 6px;
    display: inline-block;
    font-size: 14px;
    margin-right: 20px;
    margin-top: 60px;
  }
}

.logo_micro {
  display: none;
}

.menu-trigger {
  display: none;
}

.BG {
  position: absolute;
  z-index: 3;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: black;
  opacity: 0.4;
}

input[type='file'] {
  cursor: pointer;
  width: 158px;
  height: 37px;
  overflow: hidden;
  margin-right: 12px;
}
input[type='file'] {
  opacity: 0;
}
.file-input-style {
  content: 'Browse file';
  cursor: pointer;
  display: inline-block;
  width: 46%;
  height: 36px;
  background: white;
  padding: 0 2px;
  border: 1px solid #895b85;
  color: #895b85;
  border-radius: 4px;
  text-transform: uppercase;
  .text {
    font-family: 'BG-Medium';
    font-size: 10px;
    text-align: center;
    line-height: 36px;
  }
  input {
    opacity: 0;
  }
}

input[type='file']::-webkit-file-upload-button {
  visibility: hidden;
}

.add-policy-types {
  background: #fff;
  width: 310px;
  position: absolute;
  z-index: 10;
  margin-top: 65px;
  border-radius: 10px;
  box-shadow: 0px 2px 16px #ccc;
  padding: 18px 24px;

  strong {
    font-size: 18px;
    font-family: 'BG-Bold';
  }

  img {
    position: absolute;
    right: 13px;
    top: 13px;
    cursor: pointer;
  }

  .button-transparent {
    float: none !important;
    width: 100%;
    height: 31px;
    line-height: 31px;
    font-family: 'BG-Medium';

    &:first-of-type {
      margin-right: 12px;
    }
  }
}

.title-num {
  h1 {
    display: inline;
    margin-right: 20px;
  }
  span {
    font-size: 16px;
    font-family: 'BG-Medium';
    color: #767676;
    display: inline;
    position: relative;
    top: -2px;
  }
}

.user-settings {
  .container-show {
    display: flex;
    justify-content: space-between;
    font-size: 16px;
    padding-bottom: 26px;
    font-family: 'BG-Bold';
  }
  .show-box {
    color: #895b85;
    font-family: 'BG-Regular';
    text-transform: capitalize;
  }
  .show-email {
    color: #895b85;
    font-family: 'BG-Regular';
  }

  .multiple-checkbox-wrapper-view {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-end;
    .show-box {
      padding: 0;
    }
    .show-box:not(:last-child):after {
      content: ',\00a0';
    }
    .show-box-none {
      padding: 0 0 0 15px;
      color: #895b85;
      font-family: 'BG-Regular';
      opacity: 0.5;
      font-size: 14px;
      text-align: right;
    }
  }
}
.button-edit {
  margin-top: 12px;
  span {
    margin-left: 6px;
    cursor: pointer;
    padding: 12px 28px;
    background: #895c85;
    color: #fff;
    font-family: 'BG-Medium';
    text-align: center;
    border-radius: 8px;
    font-size: 14px;
  }
}

@import 'responsive-992.less';
@import 'responsive-576.less';

@media screen and (max-width: 1370px) {
  .hidden-size-lg {
    display: none;
  }
}
@media screen and (max-width: 1170px) {
  .hidden-size-md {
    display: none;
  }
}
@media screen and (max-width: 880px) {
  .hidden-size-lg {
    display: none;
  }
  .hidden-size-md {
    display: none;
  }
  .policies-list {
    white-space: nowrap;
    overflow: hidden;
    overflow-x: scroll;
  }
  .content-block .policies-list-container .policies-list.short {
    width: 100% !important;
  }
  .content-block .policies-list-container h2 {
    float: none;
  }
  .content-block .policies-list-container .noValue {
    margin-left: 25px;
  }
  .add-policy-types {
    .button-transparent {
      width: 100% !important;
    }
  }
  .content-block .policies-list-container .button-transparent:not(.fs) {
    float: none;
    text-align: center;
    margin: 28px 0 0 0;
    width: 200px;
  }
  .content-block .description-short-text {
    width: 100%;
  }
  .policy-item {
    width: 203px !important;
    height: 408px !important;
    display: inline-block !important;
    margin-right: 12px;
    vertical-align: top;

    .policy-item__title {
      padding-left: 0 !important;
      padding-bottom: 0 !important;
      text-align: center;

      span {
        width: 100%;
        padding: 0 !important;
        display: block;
      }
    }

    .policy-item__content {
      position: initial !important;
      width: 100% !important;
      height: auto !important;
      display: block !important;
      background: transparent !important;

      .policy-item__alert,
      .policy-item__expired,
      .policy-item__pending {
        width: 100% !important;
        border-top-right-radius: 8px;
        border-top-left-radius: 8px;
        border-bottom-left-radius: 0px !important;
        right: 0 !important;
      }

      .policy-item__node {
        border: 0 !important;
      }

      .policy-item__open {
        display: none;
      }
    }
  }
  .file-input-style {
    width: 100%;
  }
}

table tr td {
  padding: 0;
  .form-object {
    position: relative;
    .vida-selector {
      top: 26px;
    }
  }
}

.error-message {
  color: red;
}

.policy-item-content-travel {
  display: flex;
  justify-content: center;
  flex-direction: column;
  padding: 0px;
}

@media screen and (min-width: 320px) and (max-width: 576px) {
  .input-password {
    width: 100% !important;
    .ant-input {
      text-align: left;
    }
  }
  .user-details-header-dashboard {
    flex-direction: column;
    align-items: flex-start !important;
  }
}
