@media screen and (max-width: 992px) {
  .wrap {
    padding: 0;
  }

  .navigation .buy-policy img {
    height: 24px;
    margin: 5px;
  }

  .side-menu {
    border-radius: 0;
    width: 90px;
    height: 100%;
    left: 0;
    margin-top: 0;

    .logo_micro {
      background: url('../images/logo_micro.svg');
      width: 33px;
      height: 23px;
      margin: auto;
      margin-top: 40px;
      background-size: contain;
      background-position: center;
      background-repeat: no-repeat;
      position: relative;
      display: block;
    }

    .logo {
      width: 33px;
      height: 11px;
      margin-top: 6px;
    }

    ul {
      li {
        // margin: 19px auto 19px auto;
        margin: 0px auto 0px auto;
        text-align: center;

        img {
          width: 28px;
          height: 28px;
          margin: 0;
        }
      }
    }

    a {
      padding: 17px 0 17px 0 !important;
      display: block;

      span {
        display: none;
      }
    }

    .buy-policy {
      width: 36px;
      height: 36px;

      span {
        display: none;
      }
    }

    .logout {
      text-align: center;
      padding: 15px 0;

      img {
        width: 28px;
        height: 28px;
      }

      span {
        display: none;
      }
    }
  }

  .content-block {
    padding: 31px 0 50px 122px;
    width: calc(100% - 30px);

    .cards {
      width: 100%;
      white-space: nowrap;
      overflow: hidden;
      overflow-x: scroll;

      .title {
        white-space: normal;
      }
    }
  }

  .checkbox-md {
    margin-bottom: 15px;
  }

  .article-content {
    width: 85%;
  }

  .hc-content {
    width: 100%;
    white-space: nowrap;
    overflow: hidden;
    overflow-x: scroll;
    padding: 0 0 20px 0;
  }

  // .auth-page .auth-container {
  //   width: 350px;
  // }

  .login-or-td {
    width: 45px !important;
  }

  .auth-page .auth-container button {
    padding: 12px 28px !important;
  }
  .auth-page .btn-table img:first-child {
    padding-right: 8px;
  }

  .email-page {
    h1,
    h2,
    p {
      width: 90%;
    }
    hr,
    ul {
      width: 100%;
    }
  }

  .policy-view {
    width: 100%;
    .content .element {
      display: flex;
    }

    .button-transparent {
      margin-left: 0;
      margin-right: 15px;
    }

    .content .element p {
      width: calc(100% - 120px);
    }
  }

  .add-insurance {
    .user-details {
      .form-node {
        .vida-selector {
          width: 100%;
        }
      }
    }
  }

  .content-block .policies-list-container .policy-item .policy-item__title img {
    margin-bottom: 0;
  }
  .content-block .policies-list-container .policy-item .policy-item__title span {
    white-space: normal;
  }
}
.policy-item__node {
  margin: 7px 0;
  padding: 6px 0;
}
@media all and (max-width: 880px) {
  .clickable-wrapper {
    top: 0;
  }
  .clickable {
    margin: 0 0 5px 40px;
  }
}
