@font-face {
    font-family: "BG-Medium";
    src: url('../fonts/BrandonGrotesque/BrandonGrotesque-Medium.ttf') format("truetype"),
         url('../fonts/BrandonGrotesque/BrandonGrotesque-Medium.woff') format("woff"),
         url('../fonts/BrandonGrotesque/BrandonGrotesque-Medium.eot') format("eot");
    font-weight: 500;
}

@font-face {
    font-family: "BG-Regular";
    src: url('../fonts/BrandonGrotesque/BrandonGrotesque-Regular.ttf') format("truetype"),
         url('../fonts/BrandonGrotesque/BrandonGrotesque-Regular.woff') format("woff"),
         url('../fonts/BrandonGrotesque/BrandonGrotesque-Regular.eot') format("eot");
    font-weight: 400;
}

@font-face {
    font-family: "BG-Light";
    src: url('../fonts/BrandonGrotesque/BrandonGrotesque-Light.ttf') format("truetype"),
         url('../fonts/BrandonGrotesque/BrandonGrotesque-Light.woff') format("woff"),
         url('../fonts/BrandonGrotesque/BrandonGrotesque-Light.eot') format("eot");
    font-weight: 300;
}

@font-face {
    font-family: "BG-Bold";
    src: url('../fonts/BrandonGrotesque/BrandonGrotesque-Bold.ttf') format("truetype"),
         url('../fonts/BrandonGrotesque/BrandonGrotesque-Bold.woff') format("woff"),
         url('../fonts/BrandonGrotesque/BrandonGrotesque-Bold.eot') format("eot");
    font-weight: 700;
}

@font-face {
    font-family: "BG-Black";
    src: url('../fonts/BrandonGrotesque/BrandonGrotesque-Black.ttf') format("truetype"),
         url('../fonts/BrandonGrotesque/BrandonGrotesque-Black.woff') format("woff"),
         url('../fonts/BrandonGrotesque/BrandonGrotesque-Black.eot') format("eot");
    font-weight: 900;
}

@primary-color: #895d85;