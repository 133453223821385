.section_wrapper {
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
}

.section_title {
  display: flex;
  font-size: 25px;
  font-weight: 600;
  height: 50px;
  align-items: center;
}

.section_container {
  display: flex;
  flex-direction: column;
  border-radius: 9px;
  min-width: 340px;
  min-height: 324px;
}

.section_header {
  position: relative;
  display: flex;
  height: 60px;
  border-bottom: 1px solid #b181aa;
  align-items: center;
  background-color: #ffffff;
  border-start-start-radius: 7px;
  border-start-end-radius: 7px;
}

.section_headers_text {
  display: flex;
  box-sizing: border-box;
  margin: 0 20px;
  font-size: 15px;
  font-weight: 600;
}

.section_headers_hint {
  position: absolute;
  right: 25px;
  height: 30px;
  border: 1px solid #f03a3a;
  display: flex;
  align-items: center;
  box-sizing: border-box;
  padding: 0 10px 0 0;
  border-radius: 15px;
  background-color: #f9eeee;
}

.section_headers_hint_sign {
  height: 31px;
  width: 31px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: red;
  border-radius: 20px;
  color: white;
  font-size: 17px;
  box-sizing: border-box;
  margin: 0 10px 0 0;
}

.section_footer {
  display: flex;
  height: 50px;
  border-top: 1px solid #b181aa;
  align-items: center;
  justify-content: center;
  > span {
    cursor: pointer;
    font-size: 15px;
    text-decoration: underline;
    font-weight: 600;
  }
}

.section_headers_hint_text {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 145px;
}

@primary-color: #895d85;