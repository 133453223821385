.buy-subtitle {
  margin: 25px 0;
  font-weight: bold;
  font-size: 16px;
}

.shadowBG {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: black;
  opacity: 0.4;
}

@primary-color: #895d85;