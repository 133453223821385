/*---------------- Font Sizes ---------------------*/
.fs-9 {
  font-size: 0.5625rem !important;
}
.fs-10 {
  font-size: 0.625rem !important;
}
.fs-11 {
  font-size: 0.6875rem !important;
}
.fs-12 {
  font-size: 0.75rem !important;
}
.fs-13 {
  font-size: 0.8125rem !important;
}
.fs-14 {
  font-size: 0.875rem !important;
}
.fs-15 {
  font-size: 0.9375rem !important;
}
.fs-16 {
  font-size: 1rem !important;
}
.fs-17 {
  font-size: 1.0625rem !important;
}
.fs-18 {
  font-size: 1.125rem !important;
}
.fs-19 {
  font-size: 1.1875rem !important;
}
.fs-20 {
  font-size: 1.25rem !important;
}
.fs-21 {
  font-size: 1.3125rem !important;
}
.fs-22 {
  font-size: 1.375rem !important;
}
.fs-23 {
  font-size: 1.4375rem !important;
}
.fs-24 {
  font-size: 1.5rem !important;
}
.fs-25 {
  font-size: 1.5625rem !important;
}
.fs-26 {
  font-size: 1.625rem !important;
}
.fs-27 {
  font-size: 1.6875rem !important;
}
.fs-28 {
  font-size: 1.75rem !important;
}
.fs-29 {
  font-size: 1.8125rem !important;
}
.fs-30 {
  font-size: 1.875rem !important;
}
.fs-31 {
  font-size: 1.9375rem !important;
}
.fs-32 {
  font-size: 2rem !important;
}
.fs-40 {
  font-size: 2.5rem !important;
}
@media (min-width: 480px) {
  .fs-sm-9 {
    font-size: 0.5625rem !important;
  }
  .fs-sm-10 {
    font-size: 0.625rem !important;
  }
  .fs-sm-11 {
    font-size: 0.6875rem !important;
  }
  .fs-sm-12 {
    font-size: 0.75rem !important;
  }
  .fs-sm-13 {
    font-size: 0.8125rem !important;
  }
  .fs-sm-14 {
    font-size: 0.875rem !important;
  }
  .fs-sm-15 {
    font-size: 0.9375rem !important;
  }
  .fs-sm-16 {
    font-size: 1rem !important;
  }
  .fs-sm-17 {
    font-size: 1.0625rem !important;
  }
  .fs-sm-18 {
    font-size: 1.125rem !important;
  }
  .fs-sm-19 {
    font-size: 1.1875rem !important;
  }
  .fs-sm-20 {
    font-size: 1.25rem !important;
  }
  .fs-sm-21 {
    font-size: 1.3125rem !important;
  }
  .fs-sm-22 {
    font-size: 1.375rem !important;
  }
  .fs-sm-23 {
    font-size: 1.4375rem !important;
  }
  .fs-sm-24 {
    font-size: 1.5rem !important;
  }
  .fs-sm-25 {
    font-size: 1.5625rem !important;
  }
  .fs-sm-26 {
    font-size: 1.625rem !important;
  }
  .fs-sm-27 {
    font-size: 1.6875rem !important;
  }
  .fs-sm-28 {
    font-size: 1.75rem !important;
  }
  .fs-sm-29 {
    font-size: 1.8125rem !important;
  }
  .fs-sm-30 {
    font-size: 1.875rem !important;
  }
  .fs-sm-31 {
    font-size: 1.9375rem !important;
  }
  .fs-sm-32 {
    font-size: 2rem !important;
  }
  .fs-sm-33 {
    font-size: 2.0625rem !important;
  }
  .fs-sm-34 {
    font-size: 2.125rem !important;
  }
  .fs-sm-35 {
    font-size: 2.1875rem !important;
  }
  .fs-sm-36 {
    font-size: 2.25rem !important;
  }
  .fs-sm-40 {
    font-size: 2.5rem !important;
  }
}
@media (min-width: 767px) {
  .fs-md-9 {
    font-size: 0.5625rem !important;
  }
  .fs-md-10 {
    font-size: 0.625rem !important;
  }
  .fs-md-11 {
    font-size: 0.6875rem !important;
  }
  .fs-md-12 {
    font-size: 0.75rem !important;
  }
  .fs-md-13 {
    font-size: 0.8125rem !important;
  }
  .fs-md-14 {
    font-size: 0.875rem !important;
  }
  .fs-md-15 {
    font-size: 0.9375rem !important;
  }
  .fs-md-16 {
    font-size: 1rem !important;
  }
  .fs-md-17 {
    font-size: 1.0625rem !important;
  }
  .fs-md-18 {
    font-size: 1.125rem !important;
  }
  .fs-md-19 {
    font-size: 1.1875rem !important;
  }
  .fs-md-20 {
    font-size: 1.25rem !important;
  }
  .fs-md-21 {
    font-size: 1.3125rem !important;
  }
  .fs-md-22 {
    font-size: 1.375rem !important;
  }
  .fs-md-23 {
    font-size: 1.4375rem !important;
  }
  .fs-md-24 {
    font-size: 1.5rem !important;
  }
  .fs-md-25 {
    font-size: 1.5625rem !important;
  }
  .fs-md-26 {
    font-size: 1.625rem !important;
  }
  .fs-md-27 {
    font-size: 1.6875rem !important;
  }
  .fs-md-28 {
    font-size: 1.75rem !important;
  }
  .fs-md-29 {
    font-size: 1.8125rem !important;
  }
  .fs-md-30 {
    font-size: 1.875rem !important;
  }
  .fs-md-31 {
    font-size: 1.9375rem !important;
  }
  .fs-md-32 {
    font-size: 2rem !important;
  }
  .fs-md-33 {
    font-size: 2.0625rem !important;
  }
  .fs-md-34 {
    font-size: 2.125rem !important;
  }
  .fs-md-35 {
    font-size: 2.1875rem !important;
  }
  .fs-md-36 {
    font-size: 2.25rem !important;
  }
  .fs-md-40 {
    font-size: 2.5rem !important;
  }
}
/*---------------- Font Sizes ---------------------*/
/*---------------- Font Weights ---------------------*/
.fw-lighter {
  font-weight: lighter !important;
}
.fw-normal {
  font-weight: normal !important;
}
.fw-bold {
  font-weight: bold !important;
}
.fw-bolder {
  font-weight: bolder !important;
}
/*---------------- Font Weights ---------------------*/
