.custom_toast {
    display: flex;
    flex-direction: column;
    height: 100px;
    z-index: 10;
    background-color: #aacecd;
    border-left: 7px solid #7db7b7;
    width: 100%;
    box-sizing: border-box;
    padding: 10px;
    font-size: 16px;
}

.custom_toast-title {
    font-size: 19px;
}

.custom_toast-message {
    padding-top: 10px;
    font-size: 17px;
}
@primary-color: #895d85;