.footer-new {
  transition: height 300ms;
  position: absolute;
  padding: 10px 0;
  font-size: 0.8rem;
  height: 100px;
  background: white;
  bottom: 0;
  width: 100%;
  left: 0;
  z-index: 3;
  .container-footer{
    background-color: white;
  }
  .footer-wrapper {
    background-color: inherit;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    .footer-wrapper-iob {
      display: flex;
    }
    .footer-wrapper-col {
      margin-top: 5px;
    }
    .main-text {
      color: rgba(0, 0, 0, 0.65);
      font-size: 15px;
      .arrow-img-up {
        width: 15px;
        height: 12px;
      }
      .arrow-img-down {
        width: 15px;
        height: 11px;
      }
      &:hover {
        cursor: pointer;
        color: #b181aa;
      }
    }
  }

  .footer-flag {
    height: 17px;
    margin-right: 10px;
    margin-left: 10px;
  }

  .footer-logo {
    width: 60px;
    margin-right: 10px;
    height: auto;
  }

  .footer-icon {
    width: 24px;
    height: 24px;
    margin-left: 10px;
  }
  .hyperlinks-wrapper {
    background-color: white;
    padding: 10px;
    display: flex;
    border: 1px #dddddd solid;
    flex-wrap: wrap;
    justify-content: space-evenly;
    width: 90%;
    span {
      text-align: center;
      flex: 25%;
      margin-bottom: 10px;
      a {
        font-size: 14px;
        text-align: center;
        text-decoration: none;
        color: rgba(0, 0, 0, 0.65);
        &:hover {
          color: #b181aa;
        }
      }
    }
  }
  .hyperlinks-end-div {
    height: 10px;
  }
  &.footer-increased {
    height: 200px;
    background-color: white;
  }
}

@media only screen and (max-width: 600px) {
  .footer-wrapper {
    .hyperlinks-wrapper {
      flex-direction: column;
      text-decoration: none;
      border-radius: 5px;
      margin-bottom: 5px;
    }
  }
}

@primary-color: #895d85;