.policy-list-container {
  display: flex;
  width: 100%;
  height: 100%;
  justify-content: center;
  flex-direction: column;
  background-color: #fff;
  box-sizing: border-box;
  padding: 15px 10px 20px 10px;
}

.policy-list {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 85px;
}

.policy-list-item {
  display: grid;
  grid-template-columns: 20% 20% 1fr 1fr 1fr 1fr;
  height: 102px;
  margin: 5px 0;
  border-radius: 6px;
  cursor: pointer;
  padding: 10px 0;

  @media (max-width: 1280px) {
    display: flex;
    flex-direction: column;
    height: 100%;
  }
}

.policy-list-item_cell {
  display: flex;
  position: relative;
  align-items: center;
  justify-content: center;
  background-repeat: no-repeat;
  background-position: 15%;
  background-size: contain;
  box-sizing: border-box;
  padding: 0 10px;
  border-right: 1px solid #bbb;

  @media (max-width: 1280px) {
    display: flex;
    width: 100%;
    height: 65px;
  }
}
.price_container {
  display: flex;
  align-items: center;
  flex-direction: column;
  color: #848181;
  font-size: 12px;
}

.price_container_text {
  color: #383837;
  font-weight: 600;
  font-size: 16px;
}

.brand_container {
  display: flex;
  align-items: center;
  flex-direction: column;
  color: #848181;
  font-size: 12px;
}

.price_container_text {
  display: inline;
}

.insurance-type_text {
  display: flex;
  width: 100px;
  justify-content: center;
  font-weight: 600;
  text-transform: capitalize;
}
.data-title {
  font-size: 12px;
  color: #848181;
}

.data-value {
  font-weight: 600;
}

.policy-list-container_mobile {
  display: grid;
  background-color: #fff;
  box-sizing: border-box;
  padding: 15px 5px;
  grid-template-columns: 1fr 1fr 1fr;
  grid-gap: 5px;
  overflow-x: scroll;
  overflow-y: visible;
  max-width: 95vw;
}

.policy-list-item_mobile {
  display: flex;
  flex-direction: column;
  border: 1px solid #666;
  width: 203px;
  height: 408px;
  grid-gap: 5px;
  box-shadow: 0px 7px 16px #ddd;
  border-radius: 10px;
  grid-template-columns: 203px;
  grid-template-rows: 1fr 1fr 1fr 1fr 1fr 1fr;
}

.policy-list-item_cell_mobile {
  display: flex;
  height: 71px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.insurance-type_text_mobile {
  display: flex;
  width: 165px;
  justify-content: center;
  align-items: flex-start;
  font-weight: 600;
  font-size: 16px;
  overflow: hidden;
  word-break: break-all;
}
.insurance-type_icon {
  display: flex;
  width: 80px;
  height: 70px;
  background-position: center;
  background-size: 100%;
  background-repeat: no-repeat;
}
.insurance-type_icon_mobile {
  display: flex;
  width: 80px;
  height: 70px;
  background-position: center;
  background-size: 55px;
  background-repeat: no-repeat;
}

.price_container_mobile {
  display: flex;
  flex-direction: column;
  color: #848181;
  font-weight: 600;
  width: 100px;
  > span {
    display: flex;
    justify-content: center;
    align-items: center;
  }
}

.data-title_mobile {
  font-size: 13px;
  color: #383837;
}

.data-value_mobile {
  font-size: 16px;
  padding-top: 5px;
  white-space: normal;
  width: 95%;
  margin: auto;
  display: flex;
  justify-content: center;
  font-weight: 600;
  text-transform: uppercase;
}

._clickable {
  cursor: pointer;
  width: 120px;
  text-align: center;
  max-height: 30px;
  border: 2px solid #895c85;
  border-radius: 5px;
  padding: 2px;
}

.clickable_mobile {
  cursor: pointer;
  width: 120px;
  text-align: center;
  max-height: 30px;
  border: 2px solid #895c85;
  border-radius: 5px;
  padding: 2px;
}
._pending {
  background: #666666;
  z-index: 3;
  display: block;
  position: absolute;
  right: -2px;
  top: -12px;
  width: 240px;
  height: 24px;
  border-bottom-left-radius: 9px;
  border-top-right-radius: 9px;
  text-align: center;
  color: #fff;
  span {
    font-size: 12px;
    font-family: 'BG-Medium';
  }
}
.__expiring {
  background: #ffdd42;
  z-index: 3;
  display: block;
  position: absolute;
  right: -2px;
  top: -12px;
  width: 240px;
  height: 24px;
  border-bottom-left-radius: 9px;
  border-top-right-radius: 9px;
  text-align: center;
  color: #000;
  span {
    font-size: 12px;
    font-family: 'BG-Medium';
    margin-left: 10px;
  }
}
.__expired {
  z-index: 3;
  display: block;
  position: absolute;
  right: -2px;
  top: -12px;
  width: 240px;
  height: 24px;
  border-bottom-left-radius: 9px;
  border-top-right-radius: 9px;
  text-align: center;
  background: #de482c;
  color: #fff;
  span {
    font-size: 12px;
    font-family: 'BG-Medium';
    margin-left: 10px;
  }
}

._refresh {
  cursor: pointer;
  width: 120px;
  text-align: center;
  max-height: 30px;
  border-radius: 5px;
  padding: 2px;
  background: #ffdd42;
}

._expired {
  cursor: pointer;
  width: 120px;
  text-align: center;
  max-height: 30px;
  border-radius: 5px;
  padding: 2px;
  background-color: #de482c;
  color: #fff;
}

._buttons-container {
  display: grid;
  grid-auto-rows: 1fr 1fr;
  grid-gap: 5px;
  margin-top: 15px;
}

@primary-color: #895d85;