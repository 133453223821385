.content-block {
  // left: 0;
  // width: 90vw !important;
  .advisors {
    // padding: 10px;
     border-radius: 6px;
    .advisor-banner {
      background: url('../../images/rice-flakes-mobile.png') bottom #895d85;
      border-radius: 6px;
    }

    .advisor-landing {
      display: flex;
      background: #895d85;
      justify-content: center;
      align-items: center;
      padding: 0.5rem;
      color: white;
      border-radius: 6px;
      .landing-right {
        padding: 0 2rem;

        .landing-title {
          font-size: 44px;
          color: white;
          font-weight: 600;
          margin-bottom: 0;
          line-height: 52px;
        }

        .landing-content {
          margin-bottom: 4rem;
          font-weight: 600;
          font-size: 20px;
          line-height: 28px;
        }

        .landing-content.timer {
          display: inline-flex;
          justify-content: center;
          align-items: center;
          vertical-align: middle;
        }
      }

      .start-btn {
        background: white;
        color: #895d85;
        font-size: 0.8rem;
        justify-content: center;
        align-items: center;
        display: flex;
        font-weight: 400;
        //padding: 10px 15px 10px 15px;
        border-radius: 6px;
        cursor: pointer;
        min-width: 143px;
        max-width: 170px;
        height: 42px;
        text-transform: uppercase;
        white-space:nowrap;

        &:hover {
          border: 1px solid white;
          background: #895d85;
          color: white;
        }
      }
    }

    h1 {
      font-weight: 600;
    }
    p {
      font-family: BG-Light;
    }
    button {
      margin-top: 2rem;
      background: transparent;
      text-transform: uppercase;
      letter-spacing: 1px;
      border: none;
      outline: none;
      border: 1px solid rgba(137, 91, 133, 0.8);
      color: rgba(137, 91, 133, 0.8);
      font-size: 12px;
      width: 166px;
      height: 36px;
      text-align: center;
      line-height: 36px;
      font-size: 12px;
      &:hover {
        cursor: pointer;
      }
    }
  }

  .advisors-m {
    background: #895d85;
    //border-radius: 6px;
    margin-left: -8px;
    margin-right: -8px;
    margin-top: -21px;

    .advisor-landing {
      display: flex;
      flex-direction: column;

      .landing-right {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        color: white;
        padding: 25px;

        .landing-title {
          color: white;
          font-size: 32px;
          font-weight: bolder;
          line-height: 40px;
        }

        .landing-content {
          font-size: 20px;
          line-height: 28px;
        }

        .start-btn {
          background: white;
          color: #895d85;
          font-size: 14px;
          justify-content: center;
          align-items: center;
          display: flex;
          border-radius: 6px;
          cursor: pointer;
          margin-top: 50px;
          width: auto;
          padding: 5px 15px;
          font-weight: 400;
          line-height: 20px;
          text-transform: uppercase;
          height: 42px;
          &:hover {
            border: 1px solid white;
            background: #895d85;
            color: white;
          }
        }
      }

      .bottom-flakes {
        position: absolute;
        top: 33.3rem;
        
      }
    }
  }
}

@media only screen and (min-device-width: 360px) and (max-device-width: 760px) {
  .content-block {
    min-height: 700px !important;
    margin-left: 0px;
    //margin-right: -18px;
    .mainText {
      margin-left: 20px;
    }
  }

  .advisors-m {
    .advisor-landing {
      height: 495px;
      .landing-img-m {
        position: absolute;
        top: 24rem;
        left: -113px;
      }

      .bottom-flakes-m {
        top: 34.5rem;
        position: absolute;
        width: auto;
        width: -moz-available;
      }
    }
  }
}

//3751
@media only screen and (min-device-width: 360px) and (max-device-width: 812px) {
  .content-block {
    min-height: 700px !important;
  }

  .advisors-m {
    .advisor-landing {
      height: 501px;
      .landing-img-m {
        position: absolute;
        top: 24rem;
        left: -113px;
      }

      .bottom-flakes-m {
        top: 34.7rem;
        position: absolute;
        //width: -moz-available;
        //width: -30px;
      }
    }
  }
}

@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) {
  .content-block {
    min-height: 900px !important;
  }

  .advisors-m {
    .advisor-landing {
      height: 710px;
      background: url('../../images/rice-flakes-mobile.png') bottom #895d85 no-repeat;

      .landing-img-m {
        position: absolute;
        top: 33rem;
        left: 13px;
      }

      .bottom-flakes-m {
        top: 43.3rem;
        position: absolute;
      }
    }
  }
}

@media only screen and (min-device-width: 810px) and (max-device-width: 1080px) {
  .content-block {
    min-height: 900px !important;
  }

  .advisors-m {
    .advisor-landing {
      height: 710px;
      .landing-img-m {
        position: absolute;
        top: 33rem;
        left: 13px;
      }

      .bottom-flakes-m {
        top: 43.3rem;
        position: absolute;
      }
    }
  }
}

@primary-color: #895d85;