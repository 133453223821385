@media screen and (max-width: 576px), screen and (max-height: 530px) {
  .auth-page {
    padding-left: 0;
    padding-right: 0;
    padding-bottom: 0;
  }

  .content-block .policy-view .button-transparent {
    width: 100%;
    margin-left: 0;
  }

  .fixed-actions {
    position: fixed;
    bottom: 0;
    width: 100%;
    height: 80px;
    z-index: 100;
    display: flex !important;
    background: linear-gradient(0deg, rgba(255, 255, 255, 1), rgba(255, 255, 255, 0.3));

    .button-solid {
      color: #fff !important;
      cursor: pointer;
    }

    .btn {
      margin: auto !important;
      display: inline-block;
      width: calc(50% - 15px);
      height: 47px;
      border-radius: 8px;
      text-transform: uppercase;
      margin-top: 15px !important;
      text-align: center;
      line-height: 47px;
      padding: 0;
    }
  }

  .for-selected {
    width: 180px;
    float: left;
  }

  .policies-selected {
    margin-left: 28px;
  }

  .content-block .policies-list-container .policy-item .policy-item__content .policy-item__node {
    margin: 7px 0;
    padding: 6px 0;
  }

  .content-block .policies-list-container .policy-item .policy-item__content .policy-item__node:last-child {
    display: block;
  }

  .policy-item__content .hidden-size-md {
    display: block;
  }

  .footer {
    width: 100%;
    left: 0;

    .left {
      float: none;
      text-align: center;
    }

    .social {
      text-align: center;
      float: none;
      margin-top: 10px;
    }
  }

  .auth-page .auth-container {
    width: 100% !important;
    border-radius: 0;
    margin-top: 80px;
    float: none;
  }

  .auth-page .auth-container input[type='password'],
  .auth-page .auth-container input[type='text'] {
    width: 100%;
  }

  .btn-table {
    width: 100%;

    tr,
    td {
      display: block;
      width: 100% !important;
      text-align: center;
    }

    .login-or-td {
      margin: 15px 0;
    }
  }

  .auth-page .auth-logo img {
    margin-left: 45px;
  }

  .auth-page .auth-container label {
    font-size: 13px;
  }

  .auth-page .auth-container span {
    text-align: center;
    display: block;
    margin: auto;
  }

  .auth-page .auth-container button {
    width: 100%;
  }

  .content-block {
    padding: 116px 0px 70px 0px;
    width: 100%;
  }

  .side-menu {
    background: url('../images/icons/confetti.svg');
    background-repeat: repeat-x;
    background-color: #383837;
    width: 100%;
    height: 95px;
    margin-top: 0;
    ul,
    .buy-policy,
    .logout,
    .logo_micro {
      display: none;
    }

    .logo {
      width: 75px;
      height: 24px;
      margin: 0;
      margin-left: 15px;
      float: left;
    }

    .menu-trigger {
      display: block;
      margin-top: 35px;
      width: 24px;
      height: 24px;
      margin-right: 24px;
      float: right;
    }
  }

  .next-button {
    width: 100%;
    margin-bottom: 30px;
    text-align: center;

    &.unselect {
      width: 150px;
      position: fixed;
      z-index: 100;
      top: 0;
      height: 30px;
      line-height: 10px;
      margin-right: 40px;

      .button-solid:not(.fs) {
        padding-left: 0;
        padding-right: 0;
        background: #895c85;
        line-height: 12px;
        color: #fff;
      }
    }
  }

  .form-node {
    margin-bottom: 32px;
  }

  .article-content {
    width: 100%;
    margin-top: 10px;
  }

  .add-policy-types {
    width: 100%;
    border-radius: 0;

    .button-transparent {
      margin-top: 15px !important;
      width: 100% !important;
    }
  }

  .policy-view {
    width: 100%;

    .content {
      width: 100%;
      border-radius: 0;
      border: 0;
      margin: 0;
      clear: both;
    }

    .button-transparent {
      top: 0;
      left: 0px;
      right: 0;
      float: left;
      margin-left: 10px;
      margin-bottom: 13px;
    }
    .element {
      .mb-only,
      .fixed-actions {
        display: block !important;
      }
    }
    .mb-only,
    .fixed-actions {
      display: none !important;
    }
  }

  .hc-filter-button {
    span {
      margin-left: 20px;
    }
    img {
      left: 46px !important;
    }
  }

  .hc-content {
    width: calc(100% - 54px);
    margin: 40px 28px 0 28px;
  }

  .hc-filters {
    margin-top: 30px;
  }

  .next-button {
    .button-solid:not(.fs) {
      width: calc(100% - 54px) !important;
      margin-left: 28px;
      margin-right: 28px;
    }
  }
  .content-block .policies-list-container .button-transparent:not(.fs) {
    float: none;
    text-align: center;
    margin: 28px auto 0 auto !important;
    width: calc(100% - 54px) !important;
  }
  .side-menu a span {
    display: inline-block;
    font-size: 16px;
  }
  .side-menu ul,
  .side-menu .buy-policy,
  .side-menu .logout {
    display: block;
  }
  .navigation,
  .side-menu {
    height: 100%;
  }
  .side-menu ul li img {
    width: 22px;
    height: 22px;
    vertical-align: text-bottom;
    position: relative;
    top: 0px;
  }
  .side-menu .buy-policy {
    width: 115px;
    margin-left: 24px;
    margin-top: 16px;

    span {
      display: inline-block;
      line-height: 13px;
    }

    img {
      top: -2px;
      left: -3px;
    }
  }
  .side-menu ul {
    margin-left: 24px;
  }
  .side-menu ul li {
    text-align: left;

    span {
      padding-left: 8px;
    }
  }
  .side-menu .logout {
    margin: 0;
    margin-left: 24px;
    text-align: left;
    width: 100px;
  }
  .side-menu .logout span {
    display: inline;
    font-size: 16px;
    padding-left: 8px;
  }
  .side-menu .logout img {
    width: 22px;
    height: 22px;
  }
  .navigation {
    margin-top: 80px;
  }
  .hidden-sm {
    display: none !important;
  }
  .hidden-list {
    height: 95px !important;
  }
  .email-page ul {
    width: calc(100% - 54px);
    margin-left: 26px;
  }
  .email-page .button-solid,
  .email-page .button-transparent {
    width: calc(100% - 56px);
    margin: 10px 28px;
  }

  .title-num {
    h1 {
      display: inline;
      margin-right: 10px;
      padding-right: 0;
    }
  }
  .add-insurance {
    .user-details {
      .form-node {
        .vida-selector {
          width: 100%;
        }
      }
    }
  }
  .subtitle {
    padding-left: 0 !important;
  }
  .us.button-solid {
    width: calc(100% - 56px) !important;
    margin: 0 28px;
  }
  .mb-only {
    display: block;
  }
  .mb-off {
    display: none;
  }
  .clickable-wrapper {
    top: 0;
  }
  .clickable {
    margin: 0 0 5px 40px;
  }
}

@media screen and (min-height: 371px) and (max-height: 530px) {
  .side-menu a {
    padding: 5px 0 !important;
  }
  .side-menu .logout {
    bottom: 10px;
  }
}

@media screen and (max-height: 370px) {
  .side-menu a {
    padding: 5px 0 !important;
  }
  .logout {
    bottom: 5px;
  }
  .side-menu .menu-trigger {
    margin-top: 28px;
  }
  .side-menu .logo {
    margin-top: 18px;
  }
  .navigation {
    margin-top: 60px;
  }
  .hidden-list {
    height: 62px !important;
  }
  .content-block {
    padding-top: 86px;
  }
  .side-menu .buy-policy {
    top: -2px;
    position: absolute;
    left: 160px;
  }
}

@media all and (min-width: 320px) and (max-width: 880px) {
  .arrow-mb {
    display: block !important;
    opacity: 0.2;
    font-size: 14px;
    span {
      font-weight: bold;
    }
  }
}
