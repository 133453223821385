.main-container {
  width: 100%;
  padding: 0px 32px 32px;
  position: absolute;
  @media (max-width: 890px) {
    padding: 0px 8px 32px;
  }
}

.header-partner-wrapper {
  display: flex;
  justify-content: space-between;
  .partner-text {
    padding-top: 0;
  }
  @media all and (max-width: 580px) {
    flex-direction: column-reverse;
    .partner-wrapper {
      margin-bottom: 20px;
    }
  }
}
.subtitle-filter{
  font-size: 16px ;
}
.filter-buttons-wrapper {
  display: flex;
  .filter-buttons {
    margin: 20px 20px 20px 0;
    padding: 7px;
    min-width: 100px;
    // background-color:#895c85;
    border: none;
    border-radius: 5px;
    font-size: 16px;
    color: white;
  }
}

.simular-seguro{
  margin-top: 10px;
      background-color: #895d85;
      color: white;
      height: 40px;
      border: none;
      font-weight: bold;
      border-radius: 5px;
      font-size: 18px;
      padding: 0px 20px;
      a{
        color: white;
      }
      // width: 100%;
}
@primary-color: #895d85;