.advisor-wrapper_ {
  margin-top: 40px;
  .darkBg {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 99;
    background: fade(#000, 30%);
  }
  a {
    text-decoration: none;
  }
  .no-results {
    margin: 20px 0;
    text-align: left;
    color: #acacac;
    font-size: 16px;
    font-weight: bold;
  }
  .mandatory,
  .recommended,
  .consider {
    h2 {
      padding-top: 25px;
    }
    .info-text {
      font-size: 16px;
      font-weight: normal;
      padding-top: 0;
    }
    .wrapper_ {
      display: flex;
      align-items: flex-start;
      min-height: 186px;
      margin: 10px 0 10px 10px;
      min-width: 323px;
      overflow-x: scroll;

      @media (max-width: 1490px) {
        max-width: 770px;
      }

      .container {
        position: absolute;
        top: 0;
        left: 10%;
        width: 100%;
        height: 500px;
        z-index: 999;
      }
      .results-options_ {
        position: relative;
        background-color: fade(#895b85, 80%);
        width: 173px;
        height: 140px;
        border-radius: 20px;
        margin: 10px 15px 20px 0;
        border-bottom: 3px solid #fff;
        .icon-wrapper {
          text-align: center;
          position: relative;
          z-index: 1;
          padding: 20px;
          img {
            width: auto;
            height: 60px;
          }
          .insurance-name {
            padding: 0;
            font-size: 18px;
            line-height: 18px;
            font-weight: 600;
            color: #fff;
            width: 133px;
          }
        }
        .results-buttons {
          display: flex;
          position: absolute;
          bottom: 0;
          left: 0;
          width: 100%;
          justify-content: space-between;
          border-top: 4px solid #fff;
          .buttons {
            background: transparent;
            position: relative;
            color: #fff;
            cursor: pointer;
            width: 50%;
            font-size: 10px;
            text-align: center;
            font-weight: 400;
            text-transform: uppercase;
            line-height: 30px;
            height: 30px;
            transition: background 0.3s ease-in-out;
            &:first-child {
              border-bottom-left-radius: 20px;
              border-right: 2px solid #fff;
            }
            &:last-child {
              border-bottom-right-radius: 20px;
              border-left: 2px solid #fff;
            }
            &:hover,
            &:active {
              background: fade(#895b85, 50%);
            }
            .text {
              padding-top: 0;
            }
          }
        }
      }
      .green {
        background-color: fade(#accecd, 80%);
        .results-buttons .buttons {
          &:hover {
            background: fade(#accecd, 50%);
          }
        }
      }
      .yellow {
        background-color: fade(#f7cb8b, 80%);
        .results-buttons .buttons {
          &:hover {
            background: fade(#f7cb8b, 50%);
          }
        }
      }
      .no-results {
        background: #f5f5f5;
        position: absolute;
        width: 70%;
      }
    }
  }
}

.modal-info {
  display: flex;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 999;
  border-radius: 10px;
  justify-content: center;
  align-items: center;
  .wrapper_-info {
    display: block;
    z-index: 2;
    width: 60vw;
    height: auto;
    padding: 10px;
    background: #fff;
    border: 1px solid #ccc;
    border-radius: 8px;
    color: black;
    padding: 15px;
    .icon {
      display: block;
      width: 40px;
      height: 40px;
      border-radius: 50%;
      float: left;
      background: #fff;
    }
    .name {
      font-size: 18px;
      text-transform: none;
      background: #fff;
      padding: 0;
      float: left;
      margin-left: 10px;
      width: 57%;
      text-align: left;
      height: 40px;
      line-height: 40px;
    }
    .button-solid {
      margin-top: 6px;
      float: right;
      border-radius: 50%;
      font-family: 'BG-Regular';
      padding: 2px 9px;
    }
    .description {
      font-size: 16px;
      line-height: 24px;
      text-align: left;
      font-family: 'BG-Light';
      text-transform: none;
      margin-top: 55px;
      padding-left: 4px;
      max-height: 380px;
      overflow-y: scroll;
    }
  }
}
.typeform-wrapper {
  position: relative;
  display: block;
  width: 60vw;
  height: 60vh;
  .button-solid {
    position: absolute;
    top: 20px;
    right: 20px;
    z-index: 1;
    padding: 3px 11px;
  }
}

@media all and (max-width: 555px) {
  .modal-info {
    .wrapper_-info {
      width: 80vw;
    }
  }
}

@media (max-width: 1366px) {
  .advisor-wrapper_ {
    .mandatory,
    .recommended,
    .consider {
      .wrapper_ {
        overflow-x: scroll;
        overflow-y: visible;
        max-width: 47vw;
        .results-options {
          width: 173px;
          height: 173px;
        }
      }
    }
  }
  .typeform-wrapper {
    margin-left: 0px;
  }
}

@media (max-width: 1280px) {
  .advisor-wrapper_ {
    .mandatory,
    .recommended,
    .consider {
      .wrapper_ {
        overflow-x: scroll;
        overflow-y: visible;
        max-width: 100%;
        .results-options {
          width: 173px;
          height: 173px;
        }
      }
    }
  }
  .typeform-wrapper {
    margin-left: 0px;
  }
}

@media (max-width: 410px) {
  .advisor-wrapper_ {
    .mandatory,
    .recommended,
    .consider {
      .wrapper_ {
        overflow-x: scroll;
        overflow-y: visible;
        max-width: 344px;
        .results-options {
          width: 173px;
          height: 173px;
        }
      }
    }
  }
  .typeform-wrapper {
    margin-left: 0px;
  }
}

.bottom-grid {
  @media (max-width: 410px) {
    grid-template-columns: 360px !important;
  }

  @media (max-width: 890px) {
    grid-template-columns: 100% !important;
  }
}

// @media all and (max-width: 1024px) {
//   .advisor-wrapper {
//     .mandatory,
//     .recommended,
//     .consider {
//       .wrapper_ {
//         overflow-x: scroll;
//         overflow-y: visible;
//         max-width: 836px;
//         .results-options {
//           width: 173px;
//           height: 173px;
//         }
//       }
//     }
//   }
//   .typeform-wrapper {
//     margin-left: 0px;
//   }
// }

// @media all and (max-width: 990px) {
//   .advisor-wrapper {
//     .mandatory,
//     .recommended,
//     .consider {
//       .wrapper_ {
//         overflow-x: scroll;
//         overflow-y: visible;
//         max-width: 882px;
//         .results-options {
//           width: 173px;
//           height: 173px;
//         }
//       }
//     }
//   }
//   .typeform-wrapper {
//     margin-left: 0px;
//   }
// }

// @media all and (max-width: 890px) {
//   .advisor-wrapper {
//     .mandatory,
//     .recommended,
//     .consider {
//       .wrapper_ {
//         overflow-x: scroll;
//         overflow-y: visible;
//         max-width: 671px;
//         .results-options {
//           width: 173px;
//           height: 173px;
//         }
//       }
//     }
//   }
//   .typeform-wrapper {
//     margin-left: 0px;
//   }
// }

// @media all and (max-width: 780px) {
//   .advisor-wrapper {
//     .mandatory,
//     .recommended,
//     .consider {
//       .wrapper_ {
//         overflow-x: scroll;
//         overflow-y: visible;
//         max-width: 560px;
//         .results-options {
//           width: 173px;
//           height: 173px;
//         }
//       }
//     }
//   }
//   .typeform-wrapper {
//     margin-left: 0px;
//   }
// }

@primary-color: #895d85;