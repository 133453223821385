// Vendor
@import './vendor/antd/index';

@import '_variables';
@import '_general';
@import '_layout';
@import '_utilities';
@import '_typography';
@import '_components';

@font-face {
  font-family: 'Brandon Grotesque Regular';
  font-style: normal;
  font-weight: normal;
  src: local('Brandon Grotesque Regular'), url('../resources/fonts/Brandon_reg.woff') format('woff');
}

@font-face {
  font-family: 'Brandon Grotesque Bold';
  font-style: normal;
  font-weight: normal;
  src: local('Brandon Grotesque Bold'), url('../resources/fonts/Brandon_bld.woff') format('woff');
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

html {
  font-size: 100%;
}

body {
  margin: 0;
  padding: 0;
  font-family: Brandon Grotesque Regular;
  font-size: 0.9rem;
  line-height: 1.5;
}

#root {
  height: 100%;
}

h1 {
  font-size: 1.2rem;
}

h2 {
  font-size: 1rem;
}

h3 {
  font-size: 0.875rem;
}

.language-changer {
  margin-left: auto;
  position: fixed;
  top: 10px;
  right: 10px;
  //turn off language changes
  display: none;

  span {
    padding: 0px 7px;
    cursor: pointer;
    user-select: none;
    border-right: 1px solid #000;
    font-weight: bold;

    &:last-child {
      border-right: 0px;
    }
  }
}

.header {
  background: #fff;
  background-image: url('../../images/Header.png');
  background-repeat: repeat-x;
  max-width: 100%;
  overflow: hidden;
  height: 100px;
}

.layout__header {
  display: grid;
  grid-auto-flow: column;
  grid-column-gap: 20px;
  align-items: center;

  @media (max-width: 599px) {
    grid-auto-flow: row;
    grid-template-columns: 1fr !important;
  }

  &-back {
    color: #895d85;
    font-size: 0.8rem;
    cursor: pointer;
  }

  &-title {
    color: #895d85;
    font-size: 2.5rem;
    margin-top: 0;
    display: inline-block;
  }

  &-subtitle {
    display: inline-block;
  }
}

.header-wrapper {
  display: grid;
  grid-template-columns: auto auto 35px;
  align-items: center;
  padding-top: 35px;
}

.header-wrapper::after && .header-wrapper::before {
  box-sizing: border;
}

.header img {
  cursor: pointer;
  width: 120px;
  vertical-align: middle;
  border-style: none;
}

.language-changer {
  margin-left: auto;
  position: fixed;
  top: 10px;
  right: 10px;
  z-index: 100;
}
.main {
  background-color: #f7f7f7;
  padding-top: 40px;
  padding-bottom: 40px;
}

.ant-dropdown-link {
  margin-left: auto;
  font-size: 24px;
  color: #000;
  border: 2px solid #000;
  border-radius: 50%;
  display: grid;
  padding: 0;
  width: 32px;
  height: 32px;
  align-items: flex-end;
  justify-content: center;
}

.footer {
  padding: 20px 0;
  font-size: 0.8rem;

  .footer-wrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .footer-flag {
    height: 17px;
    margin-right: 10px;
    margin-left: 10px;
  }

  .footer-logo {
    width: 80px;
    margin-right: 10px;
  }

  .footer-icon {
    width: 24px;
    height: 24px;
    margin-left: 10px;
  }
}

@media only screen and (max-width: 480px) {
  .footer {
    font-size: 8px;

    .footer-logo {
      width: 50px;
    }

    .footer-icon {
      width: 20px;
      height: 20px;
    }
  }

  .header {
    img {
      width: 74px;
    }

    .ant-dropdown-link {
      font-size: 20px;
      width: 26px;
      height: 26px;
    }
  }


  @media only screen and (max-width: 480px) {
    .panel-question {
      margin: 40px 0 10px 0;
      width: 91%;

      .panel-question-header {
        margin-top: 30px;
        margin-bottom: 20px;
      }

      .ant-col {
        width: 100%;
      }

      .selectable__wpr {
        width: 100%;
      }

      .selectable {
        width: 100%;
      }
    }

    .panel-form-control {
      width: 100%;
    }

    .sc-htpNat.ieHlaH {
      width: 91%;
      margin-top: 50px;
    }

    .col-mobile {
      width: 100%;
    }
  }
}
