.referral_grid {
  display: grid;
  grid-template-columns: 1fr 300px;
  margin-top: 25px;
}

.form_container {
  display: flex;
  flex-direction: column;
  min-width: 315px;
  min-height: 285px;
  background-color: #fff;
  border-radius: 7px;
  padding: 20px;
  border: 2px solid rgba(137, 91, 133, 0.8);

  @media (max-width: 1020px) {
    width: 85vw;
  }
}

.form_wrapper {
  display: grid;
  height: 186px;
  border-radius: 3px;
  grid-template-columns: 1fr 1fr;
  grid-column-gap: 50px;

  @media (max-width: 1020px) {
    grid-template-columns: 1fr;
  }
}

.form_header {
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-bottom: 15px;
  color: #895b85;
  width: 100%;
}

.form_column {
  display: flex;
  flex-direction: column;

  > input {
    display: flex;
    height: 40px;
    border: 1px solid #aacecd;
    border-radius: 3px;
  }
}

.form_button {
  display: flex;
  cursor: pointer;
  padding: 12px 28px;
  background: #895c85;
  color: #fff;
  font-family: 'BG-Medium';
  text-align: center;
  border-radius: 8px;
  font-size: 18px;
  width: 160px;
  height: 50px;
}

.form_button_disabled {
  opacity: 0.3;
}

.form_actions {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.form_actions-loading {
  margin-right: 25px;
}

.form_cards_container {
  display: grid;
  margin-top: 15px;
  grid-gap: 15px;
}

.form_header-title {
  font-family: 'BG-Bold';
  font-size: 1.8em;
}

.form_header-subtitle {
  font-family: 'BG-Regular';
  font-size: 1.6em;
}

.declaration {
  margin-right: 5px;
}

input[type='checkbox'] + span {
  cursor: pointer;
  font: 16px sans-serif;
  color: black;
}

input[type='checkbox'] + span:before {
  content: '';
  border: 1px solid grey;
  border-radius: 3px;
  display: inline-block;
  width: 16px;
  height: 16px;
  margin-right: 0.5em;
  margin-top: 0.5em;
  vertical-align: -2px;
}

input[type='checkbox']:checked + span:before {
  /* NOTE: Replace the url with a path to an SVG of a checkmark to get a checkmark icon */
 
  background-image: url('../../images/tick-icon.png');
  background-repeat: no-repeat;
  background-position: center;
  /* The size of the checkmark icon, you may/may not need this */
  border: none;
  background-size: 10px;
  border-radius: 2px;
  background-color: #aacecd;
  color: white;
}
@primary-color: #895d85;