.dashboard_grid {
  display: grid;
  //min-width: 970px;
  grid-template-columns: 370px 1fr;
  grid-column-gap: 15px;
  grid-row-gap: 15px;
  margin: 0 0 40px 0;

  @media (max-width: 890px) {
    grid-template-columns: 1fr;
    min-width: 360px;
  }
}

.dashboard_progress_description {
  display: flex;
  align-items: flex-start;
  justify-content: center;
  > span {
    display: flex;
    align-items: flex-start;
    justify-content: center;
    box-sizing: border-box;
    height: 65px;
    padding: 0 31px;
    margin-top: -35px;
    max-width: 380px;
  }
}

.advices_footer {
  display: flex;
  height: 50px;
  align-items: center;
  justify-content: center;
  > span {
    cursor: pointer;
    font-size: 15px;
    text-decoration: underline;
    font-weight: 600;
  }
}

@primary-color: #895d85;