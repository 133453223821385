.referral_card {
  display: flex;
  max-height: 325px;
  width: 376px;
  margin-top: 50px;
  flex-direction: column;
  align-items: center;
  border-radius: 9px;
  padding: 0 10px;
  box-sizing: border-box;
  border: 2px solid #bad5d5;

  @media (max-width: 410px) {
    width: 360px;
  }

  .referral_card_content {
    position: relative;
    display: grid;
    align-items: center;
    height: 205px;
    grid-template-columns: 130px 1fr;
    grid-gap: 10px;
    margin: 0 20px;
  }

  .referral_card_avatar_bg {
    position: absolute;
    width: 100%;
    height: 90px;
    background-color: #bad5d5;
    z-index: 0;
    border-end-start-radius: 50px;
    border-start-start-radius: 50px;
  }

  .referral_card_avatar_wrapper {
    display: flex;
    background-color: #bad5d5;
    border-radius: 80px;
    width: 120px;
    height: 120px;
    justify-content: center;
    align-items: center;
    box-sizing: border-box;
    z-index: 1;
  }
  .referral_card_avatar {
    display: flex;
    width: 100px;
    height: 100px;
    background-size: contain;
    background-repeat: no-repeat;
    box-sizing: border-box;
    border-radius: 50px;
  }
  .referral_card_title {
    z-index: 1;
    font-size: 17px;
    cursor: pointer;
  }

  .referral_card_header {
    display: flex;
    flex-direction: column;
    box-sizing: border-box;
    width: 100%;
    height: 65px;
    border-bottom: 2px solid #bad5d5;
    justify-content: center;
    padding-left: 25px;

    .referral_card_header-title {
      display: flex;
      font-size: 21px;
      font-weight: 600;
    }

    .referral_card_header-subtitle {
      display: flex;
      font-size: 16px;
      color: #848181;
    }
  }

  .referral_card_footer {
    display: flex;
    flex-direction: column;
    box-sizing: border-box;
    width: 100%;
    height: 55px;
    align-items: center;
    border-top: 2px solid #bad5d5;
    justify-content: center;

    .referral_card_footer-title {
      z-index: 1;
      font-size: 20px;
      cursor: pointer;
      font-weight: 600;
      text-decoration: underline;
    }
  }
}

@primary-color: #895d85;