.svg {
  display: block;
  margin: 20px auto;
  max-width: 100%;
  fill: #ffffff;
}

.svg-circle-bg {
  fill: none;
  stroke-linecap: round;
}

.svg-circle {
  fill: none;
  stroke-linecap: round;
}

.svg-circle-progress {
  font-size: 50px;
  text-anchor: middle;
  fill: #000000;
  font-weight: bold;
}

.svg-circle-text {
  font-size: 16px;
  text-anchor: middle;
  fill: #000000;
  font-weight: bold;
}
