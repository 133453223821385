.form-container {
  display: flex;
}
.main-spinner-form {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  -webkit-transform: translate(-50%, -50%);
}
.edit-text {
  padding: 2px 10px;
  color: #895d85;
  text-decoration: underline;
}
.edit-text:hover {
  border: 1px solid #b5b5b5;
  padding: 2px 10px;
  border-radius: 8px;
}
.panel-class {
  // padding: 0 10px;
  background-color: white;
  border: none;
  margin-bottom: 1.4rem;
}
.panel-class-loading {
  opacity: 0.4;
}
.ant-collapse-content {
  border-top: 1px solid #ddd;
  padding: 0 10px;
  //border-top: none;
}
.ant-collapse-header {
  padding: 0 10px !important;
  border-bottom: none;
}

.ant-collapse {
  background: none;
}

.ant-collapse > .ant-collapse-item {
  border-bottom: none;
}

.tab-text {
  margin-top: 30px;
  font-size: 16px;
}

.selectable-tags-div {
  display: flex;
  flex-flow: row wrap;
  .selectable-ant-tags {
    display: flex;
    justify-content: center;
    align-items: center;
    width: auto;
    margin-right: 20px;
    font-size: 1rem;
    border-radius: 6px;
    // height: 100%;
    margin-bottom: 10px;
    border: 2px solid #ddd;
    text-align: center;
    padding: 4px 42px;
    // min-height: 88px;
    color: black;
    &:hover {
      border: 2px solid #b6d5d4;
      cursor: pointer;
    }
    p {
      margin: 0 !important;
      padding: 0 !important;
    }
  }
}

.child-div {
  padding-left: 11px;
  border-left: 6px solid #9ecbcb40;
  margin-top: 9px;

  > p {
    padding-top: 0;
    padding-bottom: 13px;
    font-size: 18px;
    font-weight: bold;
  }

  // .select-wrapper {
  //   width: 50%;
  // }
}

.saved-details {
  margin-top: 10px;
  margin-right: -23px;
  margin-left: -23px;
  border-top: 1px solid #d9d9d9;
  padding: 5px 30px 10px 30px;
}

.question-heading {
  color: #b1b1b1;
  font-weight: 600;
}
.answer-heading {
  color: black;
  font-weight: 500;
}

.form-progress-bar {
  display: flex;
  margin: 1rem;
  margin-top: 1rem;
 // border: 1px solid #d9d9d9;
  border-radius: 5px;
  height: 40vh;
  width: 20vw;
  margin-top: 75px;
  flex-direction: column;
  position: sticky;
  top: 20px;

  .form-progress-bar-title {
    height: 35%;
    padding: 1rem;
    background: #895d85;
    color: white;
    text-align: center;
    border-top-left-radius: 6px;
    border-top-right-radius: 6px;

    .icon {
      width: 23%;
      border-radius: 50%;
      padding-right: 0.3rem;
    }
  }

  .form-progress-bar-content {
    padding: 0 0.8rem;
    text-align: center;
    height: inherit;
    background: white;
    border-bottom-right-radius: 6px;
    border-bottom-left-radius: 6px;
    .svg {
      margin: 13px auto;
    }
  }
}

.svg-circle-progress {
  font-size: 19px;
}

.svg-circle-text {
  display: none;
}

.progress-bar-m {
  background: rgba(255, 255, 255, 0);
  margin-top: 0px;
  width: 100%;
  .progress-bar-inner-m {
    background: #ddd;
    padding: 0.1rem 1.3rem;
    border-radius: 29px;
    color: #000;
    font-weight: 400;
  }
}

.ant-progress-bg {
  background-color: #895d85;
}

.ant-progress-status-success .ant-progress-bg {
  background-color: #895d85;
}
.ant-progress-status-success .ant-progress-text {
  color: #895d85;
}

.mainHeader {
  display: flex;
  justify-content: space-between;
  margin-bottom: 30px;
}
.select-provider {
  margin-bottom: 30px;
  margin-top: 20px;
  &:hover {
    cursor: pointer;
  }
}
.mainText {
  line-height: 1.5;
  font-size: 32px;
  color: #895d85;
}
.back-text {
  color: #895d85;
  font-size: 0.9rem;
  //margin-bottom: 20px;
  &:hover {
    cursor: pointer;
  }
}
.badge {
  width: 30px;
  height: 30px;
  background-color: #895d85;
  text-align: center;
  line-height: 30px;
  color: white;
  border-radius: 50px;
  font-size: 15px;
}
.text {
  padding: 10px;
  color: #895d85;
  font-size: 1.6rem;
}
.heading {
  padding: 12px;
}
.badge-text {
  display: flex;
  align-items: center;
  justify-content: space-between;
  // border-bottom: 1px solid #d9d9d9;
  // padding-bottom: 10px;
}
.inner-badge-text {
  display: flex;
  align-items: center;
  .badge {
    width: 30px;
    height: 30px;
    background-color: #895d85;
    text-align: center;
    line-height: 30px;
    color: white;
    border-radius: 50px;
    font-size: 15px;
  }
}

.button-disabled {
  background-color: gray;
  opacity: 0.4;
  border: none;

  margin-top: 50px;
  padding: 15px;
  border-radius: 0.5rem;
  font-size: 1rem;
  color: white;
}
.button-enabled {
  // margin-top: 50px;
  padding: 12px 42px;
  border-radius: 6px;
  font-size: 1rem;
  color: white;
  background-color: #895d85;
  border: 1px solid #895d85;
  &:hover {
    cursor: pointer;
  }
}
.input-box {
  width: 200px;
  -moz-appearance: textfield !important;
  -webkit-appearance: none !important;
}
input[type='checkbox'] {
  position: absolute;
  opacity: 0;
  z-index: -1;
}

input[type='checkbox'] + span {
  cursor: pointer;
  font: 16px sans-serif;
  color: black;
}

input[type='checkbox'] + span:before {
  content: '';
  border: 1px solid grey;
  border-radius: 3px;
  display: inline-block;
  width: 16px;
  height: 16px;
  margin-right: 0.5em;
  margin-top: 0.5em;
  vertical-align: -2px;
}

input[type='checkbox']:checked + span:before {
  /* NOTE: Replace the url with a path to an SVG of a checkmark to get a checkmark icon */
  background-repeat: no-repeat;
  background-position: center;
  /* The size of the checkmark icon, you may/may not need this */
  border: none;
  background-size: 10px;
  border-radius: 2px;
  background-color: #aacecd;
  color: white;
}

.select-item-list {
  width: 200px;
  &:hover {
    cursor: pointer;
  }
}
.date-input-item {
  padding: 6px;
  border: none;
  border: 1px solid #d9d9d9;
  border-radius: 5px;
  width: 200px;
}
// *******************First Section******************
.firstSection {
  padding: 5px 0;
  background: white;

  .input-md {
    width: unset;
    //input date field
    height: 37.6px;
    border-radius: 6px;
    border: 2px solid #ddd;

    &:focus {
      border: 2px solid #b6d5d4;
      border-radius: 6px;
    }
  }

  .QA {
    ////margin-bottom: 20px;

    .form-object {
      width: 50%;
    }
  }

  .input-error {
    background: #ff00001a;
    padding: 20px;

    .input-md {
      border: 2px solid red !important;
      background: #fff;
    }

    .error-text {
      color: red;
    }
  }

  .question {
    font-size: 18px;
    font-weight: 600;
    line-height: 25px;
    margin-bottom: 8px;
  }

  .error-div-active {
    background: #ffe5e5;
    padding: 10px;
  }
  .div-wrapping-component {
    margin-bottom: 30px;
    margin-top: 10px;
  }
  .input-box-advisor {
    width: 201px;
  }
}

// *******************Second Section******************

.secondSection {
  padding: 5px 0;
  background: white;

  .input-md {
    width: unset;
  }

  .QA {
    ////margin-bottom: 20px;

    .form-object {
      width: 50%;
    }
  }

  .input-error {
    background: #ff00001a;
    padding: 20px;

    .input-md {
      border: 2px solid red !important;
      background: #fff;
    }

    .error-text {
      color: red;
    }
  }

  .question {
    font-size: 18px;
    font-weight: 600;
    line-height: 25px;
    margin-bottom: 8px;
  }

  .first-holder {
    display: flex;
    align-items: center;
    // .select-smoker {
    //   width: 100%;
    // }
    .first-holder-dob {
      width: 200px;
      // flex: 0.3;
      margin-right: 50px;
    }
    .first-holder-smoker {
      width: 200px;
      // flex: 0.3;
    }
  }

  .second-holder {
    margin-top: 30px;
    display: flex;
    align-items: center;
    // .select-smoker {
    //   width: 100%;
    // }
    .second-holder-dob {
      // flex: 0.3;
      margin-right: 50px;
      width: 200px;
    }
    .second-holder-smoker {
      // flex: 0.3;
      width: 200px;
    }
  }
  .error-div-active {
    background: #ffe5e5;
    padding: 10px;
  }
  .div-wrapping-component {
    margin-bottom: 30px;
    margin-top: 10px;
  }
}

// *******************Third Section******************
.thirdSection {
  padding: 5px 0;
  background: white;

  .input-md {
    width: unset;
  }

  .QA {
    //margin-bottom: 20px;

    .form-object {
      width: 50%;
    }
  }

  .input-error {
    background: #ff00001a;
    padding: 20px;

    .input-md {
      border: 2px solid red !important;
      background: #fff;
    }

    .error-text {
      color: red;
    }
  }

  .question {
    font-size: 18px;
    font-weight: 600;
    line-height: 25px;
    margin-bottom: 8px;
  }

  .error-div-active {
    background: #ffe5e5;
    padding: 10px;
  }
  .div-wrapping-component {
    margin-bottom: 30px;
    margin-top: 10px;
  }
}

.content-block p {
  padding-top: 10px;
}

#moreinfo {
  padding-top: 0px ! important;
}

.advisor-form-footer {
  //background: #895d85;
  background-image: url('../../../../images/banner-bottom-flakes.svg');
  background-repeat: no-repeat;
  padding: 1rem 0;
  justify-content: center;
  align-items: center;
  display: flex;
  bottom: 2rem;
  //position: fixed;
  width: 100%;
  height: 150px;
  object-fit: fill;

  .ft1 {
    display: inline-flex;
  }

  img {
    width: 40px;
    height: 40px;
  }

  .ftr-text {
    font-size: 1rem;
    font-weight: 600;
    color: white;
  }

  .view-result-btn {
    background: white;
    color: #895d85;
    font-size: 0.8rem;
    justify-content: center;
    align-items: center;
    display: flex;
    font-weight: 400;
    padding: 0.1rem 0;
    border-radius: 4px;
    cursor: pointer;

    &:hover {
      border: 1px solid white;
      background: #895d85;
      color: white;
    }
  }
}

@media only screen and (max-width: 1280px) {
  .mainHeader {
    flex-direction: column-reverse;
    display: flex;
    justify-content: space-between;
    margin-bottom: 0px;
  }
}


//375 to 360
@media only screen and (min-device-width: 360px) and (max-device-width: 812px) {
  .button-enabled {
    width: 100% !important;
  }

  .firstSection {
    .QA {
      .form-object {
        width: 100%;
      }
    }
  }
  .secondSection {
    .QA {
      .form-object {
        width: 100%;
      }
    }
  }
  .thirdSection {
    .QA {
      .form-object {
        width: 100%;
      }
    }
  }

  .advisor-form-footer {
    bottom: 4rem;
    width: 100%;
    // height: 20%;
    position: unset;

    img {
      height: 40px;
      width: 40px;
    }

    .view-result-btn {
      padding: 0.5rem 0;
    }
  }
}

@media only screen and (max-width: 500px) {
  // .selectable-tags-div {
  //   .selectable-ant-tags {
  //     width: 100%;
  //   }
  // }
  .input-box {
    width: 80%;
  }
  .select-smoker {
    width: 95%;
  }
  .date-input-item {
    width: 95%;
  }

  .secondSection {
    .first-holder {
      flex-direction: column;
      .first-holder-dob {
        width: 95%;
        // flex: 0.3;
        margin-right: 0px;
      }
      .first-holder-smoker {
        width: 95%;
        margin-right: 0px;
        // flex: 0.3;
      }
      .select-item-list {
        width: 95%;
      }
    }
    .second-holder {
      flex-direction: column;
      .second-holder-dob {
        width: 95%;
        // flex: 0.3;
        margin-right: 0px;
      }
      .second-holder-smoker {
        width: 95%;
        margin-right: 0px;
        // flex: 0.3;
      }
      .select-item-list {
        width: 95%;
      }
    }
  }
}

@primary-color: #895d85;