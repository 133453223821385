.card_container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-end;
  max-width: 200px;
  height: 160px;
  padding-bottom: 10px;
  font-size: 1.3em;
  border-radius: 7px;
  box-sizing: border-box;
  background-color: #fff;
  background-repeat: no-repeat;
  background-position: 50% 20%;
  background-size: 60px;
  border: 2px solid #aacecd;
}

@primary-color: #895d85;