@tablet: ~'only screen and (min-width: 601px) and (max-width: 901px)';
@mobile: ~'only screen and (max-width: 600px)';

.error-div-active {
  background: #ffe5e5;
  padding: 10px;
  .formik-errors {
    margin-top: 5px;
    color: red;
  }
}
.div-wrapping-component {
  margin-bottom: 20px;
  margin-top: 10px;
}

.selectable-tags-div {
  display: flex;
  flex-flow: row wrap;
  .selectable-ant-tags {
    display: flex;
    justify-content: center;
    align-items: center;
    width: auto;
    min-width: 120px;
    margin-right: 20px;
    font-size: 1rem;
    border-radius: 6px;
    height: 100%;
    margin-bottom: 10px;
    font-weight: bold;
    border: 2px solid #ddd;
    text-align: center;
    padding: 5px 20px;
    min-height: 42px;
    color: #000;
    &:hover {
      border: 2px solid #b6d5d4;
      cursor: pointer;
    }
    p {
      margin: 0 !important;
    }

    @media @mobile {
      width: 100%;
      margin-right: 0;
    }
  }
}

.selectable-tag-card-div {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-column-gap: 15px;
  -webkit-column-gap: 15px;
  column-gap: 15px;
  grid-row-gap: 20px;
  row-gap: 20px;
  margin-bottom: 20px;
  @media @mobile {
    display: flex;
    flex-direction: column;
  }

  .selectable-tag-card {
    background-color: white;
    border-radius: 6px;
    border: 2px solid #ddd;
    border-color: rgb(221, 221, 221);
    cursor: pointer;
    // height: 175px;
    .tag-card-title {
      display: flex;
      align-items: center;
      justify-content: center;
      height: 45px;
      font-weight: bold;
      font-size: 1rem;
      color: black;
    }
    .tag-card-body {
      flex-direction: column;
      padding: 10px;
      border-top: 1px solid #ddd;
      display: flex;
      justify-content: flex-start;
      align-items: flex-start;
    }
  }
  .selectable-tag-card-active {
    border-color: #aacecd;
    .tag-card-title-active {
      background: #aacecd;
    }
  }
}

.input-box {
  height: 40px;
  width: 200px;
  -moz-appearance: textfield !important;
  -webkit-appearance: none !important;
  @media @mobile {
    width: 100%;
  }
}
.input-box-range {
  height: 40px;
  width: 300px;
  @media @mobile {
    width: 100%;
  }
}
.input-box-country {
  width: 300px !important;
  @media @mobile {
    width: 100%;
  }
}

input[type='checkbox'] {
  position: absolute;
  opacity: 0;
  z-index: -1;
}

input[type='checkbox'] + span {
  cursor: pointer;
  font: 16px sans-serif;
  color: black;
}

input[type='checkbox'] + span:before {
  content: '';
  border: 1px solid grey;
  border-radius: 3px;
  display: inline-block;
  width: 16px;
  height: 16px;
  margin-right: 0.5em;
  margin-top: 0.5em;
  vertical-align: -2px;
}
input[type='checkbox']:checked + span:before {
  background-image: url('../../images/tick-icon.png');
  background-repeat: no-repeat;
  background-position: center;
  border: none;
  background-size: 10px;
  border-radius: 2px;
  background-color: #aacecd;
  color: white;
}
@primary-color: #895d85;