.suggestions {
  margin-left: -52px;
  width: 84.7vw;
  background-image: url('../../../images/suggestion-banner.png');
  background-repeat: no-repeat;
  background-size: contain;
  .banner {
    //background-color: #abcece;
    //min-height: 200px;
    padding-left: 30px;

    .banner-top {
      padding-top: 1rem;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      align-items: flex-start;
      h2 {
        font-weight: 600;
        letter-spacing: 1px;
        font-size: 15px;
      }
      p {
        padding-top: 7px;
        vertical-align: middle;
        width: 60%;
        font-family: 'BG-Light';
        font-size: 18px;
      }
      .banner-buttons-wrapper {
        // display: inline-flex;
        // align-items: flex-start;
        vertical-align: middle;
        width: 100%;
        display: flex;
        align-items: center;

        .banner-button {
          background: white;
          color: black;
          border: none;
          margin-right: 10px;
          border-radius: 5px;
          padding: 5px;
          min-width: 143px;
          height: 42px;
          margin-top: 10px;
        }
      }
    }
  }
  .packages {
    padding: 0px 20px 10px 20px;
    // padding-left: 126px;
    //margin-top: -120px;
    margin-left: 10px;
    .product {
      margin-bottom: 3rem;
      .card-wrapper {
        display: flex;
        // padding-left: 20px;
        margin-top: 10px;
        width: 100%;
        // height: 200px;
        // white-space: nowrap;
        position: relative;
        overflow-x: auto;
        overflow-y: hidden;
        padding-bottom: 10px;
        .card {
          //display: inline-block;
          width: 360px;
          height: 260px;
          border: 2px solid #895989;
          background: white;
          margin-right: 15px;
          padding: 10px 10px 20px 10px;
          border-radius: 5px;
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: space-between;

          p {
            text-align: center;
            // margin-top: 10px;
            padding-top: 0 !important;
          }
          .cardImageWrapper {
            padding-top: 5px;
            display: flex;
            justify-content: center;
            align-items: center;
            flex-direction: column;
            .cardImage {
              width: 50px;
              height: 50px;
            }

            .card-title {
              font-size: 1.3rem;
            }
          }
          .description {
            white-space: initial;
            text-align: start;
            padding-left: 1.2rem;
            padding-top: 0.6rem !important;
            font-family: 'BG-Light';
            font-size: 16px;
          }
          .button-wrapper {
            display: flex;
            align-items: center;
            justify-content: space-evenly;
            margin-top: 10px;
            width: inherit;
            .quote-button {
              margin-right: 10px;
              background: #895989;
              color: white;
              padding: 5px 10px;
              border-radius: 5px;
              border: none;
              min-width: 143px;
              height: 42px;
              white-space: nowrap;
            }
            .more-info-button {
              background: white;
              border: 2px solid #895989;
              padding: 5px 10px;
              border-radius: 5px;
              color: #895989;
              min-width: 143px;
              height: 42px;
              white-space: nowrap;
            }
          }
        }
      }
    }
    .product-rest {
      margin-bottom: 3rem;
      margin-top: -20px;
      .card-wrapper {
        // padding-left: 20px;
        margin-top: 10px;
        width: 100%;
        // height: 200px;
        //white-space: nowrap;
        position: relative;
        overflow-x: auto;
        overflow-y: hidden;
        display: flex;
        padding-bottom: 10px;
        .card {
          //display: inline-block;
          width: 260px;
          height: 197px;
          // height: 200px;
          //border: 1.5px solid #895989;
          box-sizing: border-box;
          background: white;
          margin-right: 20px;
          padding: 20px;
          border-radius: 6px;
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: space-around;
          p {
            text-align: center;
            // margin-top: 10px;
            padding-top: 0 !important;
          }
          .cardImageWrapper {
            padding-top: 5px;
            display: flex;
            justify-content: center;
            flex-direction: column;
            align-items: center;
            .cardImage {
              width: 50px;
              height: 50px;
            }

            .card-title {
              font-size: 1.3rem;
            }
          }
          .description {
            white-space: initial;
            text-align: start;
            padding-left: 1.2rem;
            padding-top: 0.6rem !important;
          }
          .button-wrapper {
            display: flex;
            align-items: center;
            justify-content: center;
            margin-top: 10px;

            .quote-button {
              margin-right: 10px;
              background: #895989;
              color: white;
              padding: 5px 10px;
              border-radius: 5px;
              border: none;
              min-width: 104px;
              height: 42px;
              white-space: nowrap;
            }
            .more-info-button {
              background: white;
              border: 2px solid #895989;
              padding: 5px 10px;
              border-radius: 5px;
              color: #895989;
              min-width: 104px;
              height: 42px;
              white-space: nowrap;
            }
          }
        }
      }
    }
  }
}

.modal-info.darkBg {
  // position: absolute;
  // top: 0;
  // left: 0;
  // width: 100%;
  // height: 100%;
  // z-index: 99;
  background: rgba(93, 93, 93, 0.6);
  .quote-button-container {
    display: flex;
    align-items: center;
    justify-content: center;
    .quote-button {
      margin-right: 10px;
      background: #895989;
      color: white;
      padding: 5px 10px;
      border-radius: 5px;
      border: none;
      width: 143px;
      height: 42px;
      white-space: nowrap;
    }
  }
}

.modal-info {
  display: flex;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 999;
  border-radius: 10px;
  justify-content: center;
  align-items: center;
  .wrapper-info {
    display: block;
    z-index: 2;
    width: 60vw;
    height: auto;
    padding: 10px;
    background: #fff;
    border: 1px solid #ccc;
    border-radius: 8px;
    color: black;
    padding: 15px;
    .icon {
      display: block;
      width: 40px;
      height: 40px;
      border-radius: 50%;
      float: left;
      background: #fff;
    }
    .name {
      font-size: 18px;
      text-transform: none;
      background: #fff;
      padding: 0;
      float: left;
      margin-left: 10px;
      width: 57%;
      text-align: left;
      height: 40px;
      line-height: 40px;
    }
    .button-solid {
      margin-top: 6px;
      float: right;
      border-radius: 50%;
      font-family: 'BG-Regular';
      padding: 2px 9px;
    }
    .description {
      font-size: 16px;
      line-height: 24px;
      text-align: left;
      font-family: 'BG-Light';
      text-transform: none;
      margin-top: 55px;
      padding-left: 4px;
      max-height: 380px;
      overflow-y: scroll;
    }
  }
}
.typeform-wrapper {
  position: relative;
  display: block;
  width: 60vw;
  height: 60vh;
  .button-solid {
    position: absolute;
    top: 20px;
    right: 20px;
    z-index: 1;
    padding: 3px 11px;
  }
}

@media (max-width: 1100px) {
  .suggestions {
    .banner {
      .banner-top {
        margin-left: 10px;
        .banner-text {
          h1 {
            font-size: 26px;
          }
        }
      }
    }
    .packages {
      // padding-left: 116px !important;
      margin-top: 0px;
    }
  }

  .typeform-wrapper {
    margin-left: 0px;
  }
}

@media (max-width: 768px) {
  .suggestions {
    // .banner {
    //   // padding-left: 116px;
    // }
    .packages {
      // padding-left: 116px !important;
      margin-top: 0px;
    }
  }
}

@media (max-width: 576px) {
  .suggestions {
    .banner {
      min-height: 150px;
      padding-left: 10px;
      .banner-top {
        .banner-text {
          h1 {
            font-size: 23px;
            line-height: 30px;
          }
          p {
            font-size: 15px;
          }
        }
      }
    }
    .packages {
      padding-left: 10px !important;
    }
  }

  .modal-info {
    .wrapper-info {
      width: 80vw;
    }
  }
}

@media only screen and (min-device-width: 360px) and (max-device-width: 812px) {
  .suggestions {
    margin-left: 0px;
    background-image: url('../../../images/mobile-suggestion-banner.png');
    background-repeat: no-repeat;
    background-size: contain;
    margin-top: -21px;
    margin-left: -8px;
    width: 98vw;
    .banner {
      width: 100%;
      //min-height: 275px;

      .banner-top {
        // p {
        //   width: unset;
        // }
        .banner-text {
          margin: 10px;
        }
        .banner-buttons-wrapper {
          display: flex;
          flex-direction: column;
          //margin-bottom: 20px;
          margin-left: 10px;
          align-items: flex-start;
          .wrapper-text {
            font-size: 16px;
            margin-bottom: 10px;
          }
        }
      }
    }

    .packages {
      padding-right: 10px;
      padding-top: 10px;
      //margin-top: -96px;
      margin-top: auto;

      .product {
        margin-left: 10px;
        .card-wrapper {
          .card {
            width: 267px;
            height: 322px;
            padding: 15px;

            .button-wrapper {
              flex-direction: column;

              .quote-button {
                margin: auto;
                margin-bottom: 10px;
                width: 218px;
                height: 42px;
              }

              .more-info-button {
                width: 218px;
                height: 42px;
              }
            }
          }
        }
      }

      .product-rest {
        margin-left: 10px;
        .card-wrapper {
          .card {
            width: 188px;
            height: 245px;
            padding: 15px;
            display: flex;
            align-items: center;
            justify-content: space-between;
            .button-wrapper {
              flex-direction: column;

              .quote-button {
                margin: auto;
                margin-bottom: 10px;
                width: 148px;
                height: 42px;
              }

              .more-info-button {
                width: 148px;
                height: 42px;
              }
            }
          }
        }
      }
    }
  }
}

@primary-color: #895d85;