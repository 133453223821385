@small-desktop:~'only screen and (max-width: 1200px)';
@tablet: ~'only screen and (min-width: 601px) and (max-width: 901px)';
@mobile: ~'only screen and (max-width: 600px)';
.swal2-title{
  font-size: 22px;
}
.verify-mobile {
  display: flex;
  justify-content: center;
  align-items: center;
  padding-top: 5%;
  // height: 100vh;
  // align-items: center;
  .ant-spin-dot-item {
    background-color: white;
  }
  .wrapper-div {
    //   position: absolute;
    //   left: 50%;
    //   top: 50%;
    //   transform: translate(-50%, -50%);
    background-color: white;
    border: 2px solid rgb(185, 181, 181);
    border-radius: 5px;
    width: 50%;
    padding: 50px 50px;
    display: flex;
    flex-direction: column;
    @media @small-desktop{
      width: 70%;
    }
    @media @tablet {
      width: 80%;
    }
    @media @mobile {
      width: 90%;
      padding: 20px;
    }
    // height: 80vh;
    h1 {
      font-size: 24px;
      font-weight: bold;
      color: #895d85;
      line-height: 35px;
    }
    .ordered-list {
      margin-top: 20px;
      font-size: 22px;
      font-weight: bold;
      list-style: none;
      padding-left: 0;
      li {
        font-size: 18px;
        font-weight: normal;
      }
    }
    .phone-text {
      font-size: 17px;
    }
    .phone-input-wrapper {
      display: flex;
      align-items: center;
      justify-content: flex-start;
      gap: 10px;
      .react-tel-input .form-control {
        width: 100px;
        height: 40px;
      }
      .react-tel-input {
        width: unset;
        // flex: 1;
      }
    }
    .otp-button {
      margin-top: 10px;
      background-color: #895d85;
      color: white;
      height: 40px;
      border: none;
      font-weight: bold;
      border-radius: 5px;
      font-size: 18px;
      padding: 0px 20px;
      width: 100%;
    }
    .resend-text {
      width: 100%;
      font-size: 16px;
      font-weight: bold;
      text-align: center;
      cursor: pointer;
    }
    .disabled-text {
      opacity: 0.5;
      cursor: no-drop !important;
    }
    .sign-out {
      text-align: center;
      color: #895d85;
      font-size: 16px;
      font-weight: bold;
    }

    .verify-otp-div {
      display: flex;
      flex-direction: column;
      justify-content: center;
      // align-items: center;
    }
  }
}
.back-text{
  font-size: 16px;
}
@primary-color: #895d85;